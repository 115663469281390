import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { loginRequest } from '../../httphandler/urlmapper';
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import useProfile from './../../hooks/useProfile';
const Login = () => {
    let navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const { profile, setProfile } = useProfile();
    const validationSchema = yup.object().shape({
        email: yup.string().email().required("Please enter email address."),
        password: yup.string().required("Please enter password.")
    });
    const initialValues = {
        email: '',
        password: ''
    };
    const onSubmit = async (values) => {
        const result = await loginRequest({username:values.email,password:values.password});
        if(result && result.status===401){
            setErrorMessage("Email or password not correct.");
        }else {
            
            localStorage.setItem("_token", result.data.accessToken);
            const decoded = jwt_decode(result.data.accessToken);
            setProfile({
                id:decoded.id,
                fullName:decoded.fullName,
                role:decoded.role,
                username:decoded.username
            });
            decoded.role==="super admin" ? navigate('/organizations', { replace: true }) :navigate('/dashboard', { replace: true });

        }
    }

    const renderError = (message) => <p className="help is-danger">{message}</p>
    return (
        <>
            <div className=' login-page'>
                <div className="login-box">
                    <div className="login-logo">
                        <NavLink to="/"> <img width='200' src="dist/img/trp_logo.png" alt="" className="brand-image" style={{ opacity: '.8' }} /></NavLink>
                    </div>

                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Sign in to start your session</p>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={async (values, { resetForm }) => {
                                    await onSubmit(values);
                                    resetForm();
                                }}

                            >
                                <Form>
                                    <div className="input-group mb-3">
                                        <Field type="email" name="email" className="form-control" placeholder="Email" />

                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope"></span>
                                            </div>
                                        </div>

                                    </div>
                                    <ErrorMessage name="email" render={renderError} />
                                    <div className="input-group mb-3">
                                        <Field type="password" name="password" className="form-control" placeholder="Password" />
                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-lock"></span>
                                            </div>
                                        </div>
                                    </div>
                                    <ErrorMessage name="password" render={renderError} />
                                    <div className="row">
                                        {/* <div className="col-8">
                                        <div className="icheck-primary">
                                            <input type="checkbox" id="remember" />
                                            <label for="remember">
                                                Remember Me
                                            </label>
                                        </div>
                                    </div> */}
                                    <div style={{color:'red'}}>
                                        {errorMessage}
                                        <br></br>
                                    </div>
                                        <div className="col-12">
                                            <button type="submit" className="btn btn-primary btn-block">Sign In</button>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>



                            <NavLink to='/forgot-password' className="mb-1">
                                    <p>
                                    I forgot my password.
                                    </p>
                                </NavLink>

                            {/* <p className="mb-1">
                            <a href="forgot-password.html">I forgot my password</a>
                        </p>
                        
                        <p className="mb-0">
                            <a href="register.html" className="text-center">Register a new membership</a>
                        </p> */}
                        </div>
                       
                    </div>
                    <br></br>
                    <div className="login-logo-buttom">
                           <p>Copyright @{new Date().getFullYear()} Powered by  <b>The Data Business</b> </p>
                    </div>
                </div>
            </div>

        </>)
}

export default Login;