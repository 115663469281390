
import NevHeader from './../../shared/pages/nev-header';
import { Formik, Field, Form, ErrorMessage } from 'formik';
import { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import * as yup from 'yup';
import OrganizationListPups from './views/organization-popup'
import {
    getAllOrganizations,
    addUpdateOrganization,
    sendResetPasswordEmail
} from './../../httphandler/urlmapper';


const OrganizationComponent = () => {
    const [organizationList, setOrganisationList] = useState([]);
    const [orgState, setOrgState] = useState(true);
    const [orgItem, setOrgItem] = useState();
    useEffect(() => {
        getAllOrganizationsList();
    }, []);
  
    const [initialValues, setinitialValues] = useState({
        id:0,
        orgName: '',
        usersAllowed: '',
        is_Active: true,
        email:''
    });
    const [isShow, setIsShow] = useState(false);
    const [orgId, setOrgId] = useState(0);
   
    const validationSchema = yup.object().shape({
        orgName: yup.string().required("Please enter organization name."),
        usersAllowed: yup.string().required("Please enter number of users allowed."),
        email:yup.string().email('Please select valid email.').required('Please select email.')
    });
    const onSubmit = async (values) => {
        await addUpdate(values);
    }
    const addUpdate = async (values) => {
        const result = await addUpdateOrganization(values);
        if (result && result.statusCode === 200) {
            await getAllOrganizationsList();
            toast.success('Organization updated successfully');
            return;
        } else if (result.status === 409) {
            toast.error('Organization already exist.')
        }
    }
    const getAllOrganizationsList = async () => {
        const result = await getAllOrganizations();
        if (result && result.statusCode === 200) {
            setOrganisationList(result.data);
            return;
        }
        setOrganisationList([]);
    }
    const onActiveInActiveOrg = async () => {
        setOrgState(!orgState);
    }
    const edit = async (obj) => {
        
        setinitialValues({...obj});
        setOrgState(obj.is_Active);
    }
    const onReset = () => {
        
        setinitialValues({});
        setinitialValues({
            id: 0,
            orgName: '',
            usersAllowed: '',
            is_Active: true,
            email:''
        });
        setOrgState(true);
    }
    const resetPassword = async (id)=>{
        let result = await sendResetPasswordEmail(id);
        if(result && result.statusCode === 200){
            toast.success('Reset password email has been sent.');
        }
    }
    const onClose = async ()=>{
        setIsShow(false);
        await getAllOrganizationsList();
    }
    const show =(id)=>{
        setOrgId(id);
        setIsShow(true);
        setOrgItem(organizationList.find(x=>x.id==id));
    }
    const renderError = (message) => <p className="help is-danger">{message}</p>
    return (<>
        <NevHeader routValues={{ pageRoute: '/reports', pageName: 'Manage Organization' }} />
        <section className="content">
            <div className="container-fluid">
                <div className="row">
                    <div className="col-md-12">
                        <div className="card card-primary">
                            <di v className="card-header">
                                <h3 className="card-title">Add Organization</h3>
                                <div className="card-tools">
                                    {/* <button onClick={(e) => { showAddUsersPopup() }} type="button" data-toggle="modal" data-target="#modal-lg"
                                        className="btn btn-tool" title="Add new use">
                                        Add <i className="fas fa-plus-circle"></i>
                                    </button> */}
                                </div>
                            </di>

                            <div>
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        await onSubmit(values);
                                         resetForm();
                                    }}
                                    enableReinitialize={true}
                                >
                                    <Form>
                                        <div className="modal-body">
                                            <div className="row">
                                            <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Organization Email</label>
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            name="email"
                                                        />
                                                    </div>
                                                    <ErrorMessage name="email" render={renderError} />
                                                </div>
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Organization Name</label>
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            name="orgName"
                                                        />
                                                    </div>
                                                    <ErrorMessage name="orgName" render={renderError} />
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label>No of Users Allowed</label>
                                                        <Field
                                                            type="number"
                                                            className="form-control"
                                                            name="usersAllowed"
                                                        />
                                                    </div>
                                                    <ErrorMessage name="usersAllowed" render={renderError} />
                                                </div>
                                                <div className="col-md-1">
                                                <label>Status</label>
                                                    <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                        <Field onClick={onActiveInActiveOrg} type="checkbox" name="is_Active" className="custom-control-input" id="customSwitch3" />
                                                        <label className="custom-control-label" htmlFor="customSwitch3">
                                                            {
                                                                orgState ?
                                                                    <span style={{ color: 'green' }}>Active</span> :
                                                                    <span style={{ color: 'red' }}>Inactive</span>
                                                            } </label>
                                                    </div>
                                                  
                                                </div>
                                                <div className="col-md-1">
                                                    <label>_</label>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary">
                                                        {
                                                            initialValues.id && initialValues.id>0 ? "Update":"Add"
                                                        }
                                                        </button>
                                                    </div>
                                                </div>
                                                <div className="col-md-2">
                                                    <label>_</label>
                                                    <div className="form-group">
                                                        <button onClick={(e)=>{onReset()}} type="button" className="btn btn-info">
                                                                 Reset 
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid">
                    <div className="card-body table-responsive p-0">
                        <table className="table table-hover text-nowrap">
                            <thead>
                                <tr>
                                    <th>Sr#</th>
                                    <th>Name</th>
                                    <th>Email</th>
                                    <th>No of Users</th>
                                    <th>Status</th>
                                    <th>Action</th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    organizationList && organizationList.length > 0 && organizationList.map((item, index) => {
                                        return (
                                            <>
                                                <tr key={index} onClick={(e)=>{show(item.id)}}>
                                                    <td>{index + 1}</td>
                                                    <td>{item.orgName}</td>
                                                    <td>{item.orgEmail}</td>
                                                    <td>{item.usersAllowed}</td>
                                                    <td>  {
                                                        item.is_Active ? <><span style={{ color: 'green' }}>Active</span></> : <><span style={{ color: 'red' }} className="text-danger"> Inactive

                                                        </span></>
                                                    }</td>
                                                    <td>
                                                        {/* <button onClick={(e)=>{edit(item)}} className="btn btn"><i className="fa fa-edit"></i> Edit</button> |  */}
                                                        <button onClick={(e)=>{resetPassword(item.userId)}} className="btn btn"><i className="fa fa-key"></i> Reset Password</button>
                                                    </td>
                                                </tr>
                                            </>
                                        );
                                    })
                                }
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </section>
        <>
            {
                isShow ? <>
                    <OrganizationListPups isShow={isShow} id={orgId} data={orgItem}  onClose={(e) => { onClose() }} />
                </> : ''
            }
        </>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <ToastContainer />
    </>)
}
export default OrganizationComponent;