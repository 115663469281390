/* eslint-disable jsx-a11y/anchor-is-valid */
import { NavLink } from 'react-router-dom';
import { getProfile } from '../../utils/general';
const SideBar = () => {
    const profile = getProfile();
    return (<>

        <aside className="main-sidebar sidebar-dark-primary elevation-4">

            <NavLink to='/' className="brand-link">
                <img src="dist/img/trp_logo_circul.png" alt="" className="brand-image" style={{ opacity: '.8' }} />
                <span className="brand-text font-weight-light">Work View</span>
            </NavLink>


            <div className="sidebar">



                <nav className="mt-2">
                    <ul className="nav nav-pills nav-sidebar flex-column" data-widget="treeview" role="menu" data-accordion="false">
                        {
                            profile?.role === "super admin" ?
                                <>
                                    <li className="nav-item">
                                        <NavLink to='/organizations' className="nav-link">
                                            <i className="nav-icon fa fa-building"></i>
                                            <p>
                                                Manage Organization
                                            </p>
                                        </NavLink>
                                    </li>
                                </> : <>
                                    <li className="nav-item">
                                        <NavLink className="nav-link" to="/dashboard">
                                            <i className="nav-icon fas fa-tachometer-alt"></i>
                                            <p>
                                                Dashboard
                                            </p>
                                        </NavLink>
                                    </li>
                                    <li className="nav-item">
                                        <NavLink to='/timesheets' className="nav-link">
                                            <i className="nav-icon fas fa-calendar-alt"></i>
                                            <p>
                                                Manage Time Sheet
                                            </p>
                                        </NavLink>
                                    </li>
                                    {
                                        profile?.role !== 'standard' ?
                                            <>
                                                <li className="nav-item">
                                                    <NavLink to="/users" className="nav-link">
                                                        <i className="nav-icon fas fa-users"></i>
                                                        <p>
                                                            Manage Users
                                                        </p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/clients" className="nav-link">
                                                        <i className="nav-icon fas fa-user"></i>
                                                        <p>
                                                            Manage Clients
                                                        </p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to="/settings" className="nav-link">
                                                        <i className="nav-icon fas fa-cog"></i>
                                                        <p>
                                                            Settings
                                                        </p>
                                                    </NavLink>
                                                </li>
                                                <li className="nav-item">
                                                    <NavLink to='/reports' className="nav-link">
                                                        <i className="nav-icon fas fa-file"></i>
                                                        <p>
                                                            Reports
                                                        </p>
                                                    </NavLink>
                                                </li>

                                            </> : ''
                                    }
                                </>
                        }



                    </ul>
                    <div class="nav-bottom">
                        <p >Powered by</p>
                        <img alt='' src="dist/img/logo-blue.png" />

                    </div>
                </nav>

                <div>

                </div>
            </div>

        </aside>

    </>)
}
export default SideBar;