import { React, useState,useEffect,useRef } from "react";
import Calendar from "./libs/calendarview";
import AddTimeSheetPopup from './popups/addtimesheet-popus';

const Dashboard = () => {
    const celenderRef = useRef(null);
    useEffect(() => {
        
    }, [])
    

    const [isShow, setIsShow] = useState(false);
    const onClosePopup = () => {
        setIsShow(!isShow);
        celenderRef.current.loadTimeSheetFromParent();
      }
    return (
        <>
            {/* <NevHeader routValues={{ pageRoute: '/', pageName: '' }} /> */}
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-body p-0">
                                    <Calendar
                                    ref={celenderRef}
                                    >
                                        
                                    </Calendar>
                                </div>

                            </div>
                            <button className="btn-overlapshowing" onClick={(e)=>{ setIsShow(!isShow)}} title="Add time sheet" data-toggle="modal" data-target="#modal-addTimeSheet">+</button>
                        </div>
                    </div>
                </div>
            </section>
            <>
                {
                    isShow ? <>
                        <AddTimeSheetPopup isShow={isShow} modelTitle={"Add Time Sheet"} onClose={(e) => {onClosePopup() }} />
                    </> : ''
                }
            </>
        </>)
}

export default Dashboard