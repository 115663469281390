/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getAllWorkTypeDD,
    addUpdateWorkType

} from '../../../httphandler/urlmapper';
const WorkTypePopup = (props) => {
    const [workTypeList, setWorkTypeList] = useState([]);
    const [workTypeStatus, setWorkTypeStatus] = useState(true);
    useEffect(() => {
        getAllWorkTypeList();
    }, []);

    const [initialValues, setinitialValues] = useState(
        {
            id: 0,
            workTypeName: '',
            productive: false,
            retired: true
        }
    );
    const validationSchema = yup
        .object()
        .shape(
            {
                workTypeName: yup.string().required('Please type work type.')
            });
    const onSubmit = async (values) => {
        values.retired = !values.retired;
        const result = await addUpdateWorkType(values);
        if (result.statusCode === 200) {
            toast.success("Your request has been submitted ");
            await getAllWorkTypeList();
        } else if (result.status === 409) {
            toast.error('Worktype already exist.')
        }
        resetForm();
    }
    const getAllWorkTypeList = async () => {
        const result = await getAllWorkTypeDD();
        if (result.statusCode === 200) {
            setWorkTypeList(result.data);
        }
    }
    const editWorkType = async (obj) => {
        //setinitialValues({});
        setWorkTypeStatus(!obj.retired);
        setinitialValues({
            id: obj.id,
            workTypeName: obj.workTypeName,
            productive: obj.productive,
            retired: !obj.retired
        });
    }
    const onWorkTypeStatus = (event) => {
        setWorkTypeStatus(event.target.checked);
    }
    const resetForm = () => 
    {
        setinitialValues({
            id: 0,
            workTypeName: '',
            retired: true,
            productive: false,
        });
        setWorkTypeStatus(true);
    }
    const renderError = (message) => <p className="help is-danger">{message}</p>
    return (<>
        <div className="modal fade" id="modal-lg-workType" aria-hidden="true">
            <div className="modal-dialog modal-xl">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Manage Work Type</h4>
                        <button type="button" className="close" data-dismiss="modal" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { resetForm }) => {
                                await onSubmit(values);
                                 resetForm();
                            }}
                            enableReinitialize={true}
                        >
                            <Form>
                                <div className="row">
                                    <div className="col-md-4" data-select2-id="30">
                                        <div className="form-group" data-select2-id="29">

                                            <Field type="text" name="workTypeName" placeholder="Work Type Name" className="form-control" />
                                            <ErrorMessage name="workTypeName" render={renderError} />
                                        </div>

                                    </div>
                                    <div className="col-md-3" data-select2-id="30">
                                        <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <Field type="checkbox" name="productive" id="customSwitch1" />
                                            <label htmlFor="customSwitch1">   Is Productive </label>
                                        </div>
                                    </div>
                                    <div className="col-md-2" data-select2-id="30">
                                        <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <Field onClick={onWorkTypeStatus} type="checkbox" name="retired" className="custom-control-input" id="customSwitch3" />
                                            <label className="custom-control-label" htmlFor="customSwitch3">

                                                {
                                                    workTypeStatus ?
                                                        <span style={{ color: 'green' }}>Active</span> :
                                                        <span style={{ color: 'red' }}>Inactive</span>
                                                }
                                            </label>
                                        </div>
                                    </div>
                                    <div className="col-md-2">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {
                                                    initialValues && initialValues.id > 0 ? <>Update</> : <>+</>
                                                }
                                            </button> | <button type="button" className="btn btn-primary" onClick={(e) => resetForm()}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>

                        </Formik>
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-hover text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>Sr#</th>
                                            <th>Work Type</th>
                                            <th>Is Productive</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            workTypeList && workTypeList.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.workTypeName}</td>
                                                            <td>{
                                                                item.productive ? "Yes" : "No"
                                                            }</td>
                                                            <td>
                                                                {
                                                                    item.retired ? <><span style={{ color: 'red' }} className="text-danger"> Retired

                                                                    </span></> : <><span style={{ color: 'green' }}>Active</span></>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="margin">
                                                                    <div className="btn-group">
                                                                        <button type="button" className="btn btn-default">Action</button>
                                                                        <button type="button"
                                                                            className="btn btn-default dropdown-toggle dropdown-icon"
                                                                            data-toggle="dropdown">
                                                                        </button>
                                                                        <div className="dropdown-menu" role="menu">
                                                                            <a onClick={(e) => { editWorkType(item) }} className="dropdown-item" href="#"><i className="fa fa-edit"></i>
                                                                                Edit</a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </>)
                                            })
                                        }



                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <ToastContainer
            position="top-right"
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
        />
        <ToastContainer />
    </>)
}
export default WorkTypePopup;