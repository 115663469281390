/* eslint-disable jsx-a11y/anchor-is-valid */
import { getProfile } from '../../utils/general';
import { useState } from 'react';
import ResetPasswordDialog from './resetpassword-popup';
const Header = () => {
    const profile = getProfile();
    const [isShow,setIsShow]= useState(false);
    const onClose = () => {
        setIsShow(!isShow);
    }
    return (
        <>
            <nav className="main-header navbar navbar-expand navbar-white navbar-light">
                <ul className="navbar-nav">
                    <li className="nav-item">
                        <a className="nav-link" data-widget="pushmenu" href="#" role="button"><i className="fas fa-bars"></i></a>
                    </li>
                    {/* <li className="nav-item d-none d-sm-inline-block">
                        <a href="index3.html" className="nav-link">Home</a>
                    </li>
                    <li className="nav-item d-none d-sm-inline-block">
                        <a href="#" className="nav-link">Contact</a>
                    </li> */}
                </ul>
                <ul className="navbar-nav ml-auto">
                    <li className="nav-item">
                        <div className="navbar-search-block">
                            <form className="form-inline">
                                <div className="input-group input-group-sm">
                                    <input className="form-control form-control-navbar" type="search" placeholder="Search" aria-label="Search" />
                                    <div className="input-group-append">
                                        <button className="btn btn-navbar" type="submit">
                                            <i className="fas fa-search"></i>
                                        </button>
                                        <button className="btn btn-navbar" type="button" data-widget="navbar-search">
                                            <i className="fas fa-times"></i>
                                        </button>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                        <a className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-user"></i> {profile?.fullName}
                            {/* <span className="badge badge-danger navbar-badge">3</span> */}
                        </a>
                        <div className="dropdown-menu dropdown-menu-lg dropdown-menu-right">
                            <a className="dropdown-item">
                                <div className="media">
                                    {/* <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" /> */}
                                    <div className="media-body">
                                        <h3 className="dropdown-item-title">
                                            {profile?.fullName}
                                            <span className="float-right text-sm text-danger"><i className="fas fa-star"></i></span>
                                        </h3>
                                        <p className="text-sm">{profile?.role}</p>
                                      
                                    </div>
                                </div>
                            </a>
                            <a className="dropdown-item">
                                <div className="media">
                                    {/* <img src="dist/img/user1-128x128.jpg" alt="User Avatar" className="img-size-50 mr-3 img-circle" /> */}
                                    <div className="media-body">
                                        <h3 onClick={(e)=>setIsShow(!isShow)} className="dropdown-item-title">
                                           Change Password
                                        </h3>
                                    </div>
                                </div>
                            </a>
                        </div>
                    </li>
                    <li className="nav-item dropdown">
                    <a onClick={(e)=>{localStorage.clear();window.location.href='/login'}} className="nav-link" data-toggle="dropdown" href="#">
                            <i className="far fa-exit"></i> Logout
                        </a>
                    
                    </li>
                </ul>
            </nav>
            {
                isShow ? <><ResetPasswordDialog show={isShow} onHide={() => onClose()}></ResetPasswordDialog></>:""
            }
        </>
    )
}
export default Header;