import React from 'react';
import SideBar from '../pages/sidebar';
import Header from './../pages/header';
const Layout = ({ children }) => {
    // useEffect(() => {
    //   return () => {
    //     alert('Hi i am layout')
    //   }
    // }, [])

    return (
        <React.Fragment>
            <div className="wrapper">

                <Header />
                <SideBar />
                <div className="content-wrapper">
                    <main>{children}</main>
                </div>
            </div>
        </React.Fragment>
    );
};
export default Layout;