/* eslint-disable no-unused-vars */
/* eslint-disable jsx-a11y/anchor-is-valid */
import NevHeader from "../../shared/pages/nev-header";
import { useState } from 'react';
import AddUpdateUserPopup from "./popups/addupdate-user-pupup";
import { useEffect } from 'react';
import AssingClientPopups from './popups/assignClient-popups';
import { getAllUsers, sendResetPasswordEmail } from './../../httphandler/urlmapper';
import { ToastContainer, toast } from 'react-toastify';
import { getProfile } from "../../utils/general";
import { styles } from './../../shared/css/stylecss';

const ManageUsers = () => {
    const [isShow, setIsShow] = useState(false);
    const [isShowAsigPupup, setIsShowAsigPupup] = useState(false);
    const [usersList, setUsersList] = useState([]);
    const [usersData, setUserData] = useState({});
    const [searchData, setSearchData] = useState({});
    const [userId, setUserId] = useState(0);

    useEffect(() => {
        getAllUsrsList();
    }, []);
    const showAddUsersPopup = (data = {}) => {
        setUserData(data);
        setIsShow(!isShow);

    }
    const showAssingClientPopup = (id) => {
        setUserId(id);
        setIsShowAsigPupup(!isShowAsigPupup);
    }
    const onClose = () => {
        setIsShow(!isShow);
        setIsShowAsigPupup(!isShowAsigPupup);
    }
    const getAllUsrsList = async () => {
        let result = await getAllUsers();
        setUsersList(result.data);
        setSearchData(result.data);
    }
    const search = (value) => {
        if (value) {
            setSearchData(usersList.filter((x =>x.fullName.includes(value) || x.username.includes(value) || x.role.includes(value) ))); 
        }else {
            setSearchData(usersList);
        }
    }
    const resetPasswordEmail = async (id) => {
        const result = await sendResetPasswordEmail(id);
        if (result.statusCode === 200) {
            toast.success('Reset password email sent successfully.');
        }
    }
    return (
        <>
            <NevHeader routValues={{ pageRoute: '/users', pageName: 'Manage Users' }} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Quick Search: <input onChange={(e)=>{search(e.target.value)}} style={styles.carsearchFiled}></input></h3>
                                    <div className="card-tools">
                                        <button onClick={(e) => { showAddUsersPopup() }} type="button" data-toggle="modal" data-target="#modal-lg"
                                            className="btn btn-tool" title="Add new use">
                                            Add <i className="fas fa-plus-circle"></i>
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    {/* <form>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <div className="input-group input-group-sm">
                                                    <input placeholder="Search by name" type="text"
                                                        className="form-control" />
                                                    <span className="input-group-append">
                                                        <button type="button"
                                                            className="btn btn-info btn-flat">Search!</button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form> */}
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Sr#</th>
                                                <th>Full Name</th>
                                                <th>Email</th>
                                                <th>Role</th>

                                                <th>Created Date/By</th>
                                                <th>Modify Date/By</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                searchData.length > 0 ? searchData.map((item, index) => {
                                                    return (
                                                        <>
                                                            <tr>

                                                                <td>{index + 1}</td>
                                                                <td>{item.fullName}</td>
                                                                <td>{item.username}</td>
                                                                <td>{item.role}</td>
                                                                <td>{item.createdDate ? new Date(item.createdDate).toLocaleString() + ' /' : '-'}  {item.createdBy}</td>
                                                                <td>{item.modifyDate ? new Date(item.modifyDate).toLocaleString() + ' /' : '-'}  {item.modifyBy}</td>
                                                                <td>{
                                                                    !item.is_active ? <><span style={{ color: 'red' }}>Inactive</span></> :
                                                                        <><span style={{ color: 'green' }}>Active</span></>
                                                                }</td>
                                                                <td>
                                                                    <div className="margin">
                                                                        <div className="btn-group">
                                                                            <button type="button"
                                                                                className="btn btn-default">Action</button>
                                                                            <button type="button"
                                                                                className="btn btn-default dropdown-toggle dropdown-icon"
                                                                                data-toggle="dropdown">
                                                                            </button>
                                                                            <div className="dropdown-menu" role="menu">
                                                                                <a onClick={(e) => { showAddUsersPopup(item) }} type="button" data-toggle="modal" data-target="#modal-lg" className="dropdown-item"><i
                                                                                    className="fa fa-edit"></i> Edit</a>
                                                                                <a onClick={(e) => { showAssingClientPopup(item.id) }} data-toggle="modal" data-target="#modal-clients" className="dropdown-item" href="#"><i
                                                                                    className="fa fa-user"></i> Assign Clients</a>
                                                                                {
                                                                                    getProfile().role === 'manager' && item.role === 'standard' ?
                                                                                        <a className="dropdown-item" href="#" onClick={(e) => { resetPasswordEmail(item.id) }}><i className="fa fa-key"></i> Reset Password</a> : ''
                                                                                }
                                                                                {
                                                                                    getProfile().role === 'admin' ?
                                                                                        <a className="dropdown-item" href="#" onClick={(e) => { resetPasswordEmail(item.id) }}><i className="fa fa-key"></i> Reset Password</a> : ''

                                                                                }

                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </>);
                                                }) : <></>
                                            }



                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <>
                {
                    isShow ?
                        <AddUpdateUserPopup data={usersData} getAllUsrsListEvent={getAllUsrsList} modelTitle={"Update"} onClose={onClose}></AddUpdateUserPopup> : ""
                }
                {
                    isShowAsigPupup ?
                        <AssingClientPopups data={userId} modelTitle={"Assing Clients to users"} onClose={onClose}></AssingClientPopups> : ""
                }
            </>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </>);

}
export default ManageUsers;