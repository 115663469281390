import React, { useEffect, useState, forwardRef, useImperativeHandle, useRef } from 'react'
import FullCalendar from '@fullcalendar/react' // must go before plugins
import momentPlugin from '@fullcalendar/moment';
import interactionPlugin from "@fullcalendar/interaction" // needed for dayClick
import timeGridPlugin from "@fullcalendar/timegrid";
import moment from 'moment';
import {
  getApprovedTimeSheet
} from '../../../httphandler/urlmapper';
import UserListPopup from './users-list-popup';
import AddTimeSheetPopup from './../popups/addtimesheet-popus';
import { getProfile } from '../../../utils/general';
const Calendar = forwardRef((props, reff) => {
  useImperativeHandle(reff, () => ({
    loadTimeSheetFromParent() {
      getTimeSheet();
    },
  }));
  const [ScreenHight, setScreenHight] = useState(0);
  const [timesheets, setTimesheets] = useState([]);
  const [isShowUsersPopup, setIsShowUsersPopup] = useState(false);
  const [selectedUser, setSelectedUser] = useState('Select User');
  const [searchedUserId, setsearchedUserId] = useState(0)
  const [isShow, setIsShow] = useState(false);
  const [isView, setIsView] = useState(false);
  const [timeSheetId, setTimeSheetId] = useState(0);
  const ref = useRef();
  useEffect(() => {
    const profile = getProfile();
    let hight = window.screen.height * 0.83;

    setScreenHight(hight);
    //loadApprovedTimeSheet();
    getTimeSheet();
    setSelectedUser(profile.role === "standard" ? profile.fullName : 'Select User')
  }, []);
  // const loadApprovedTimeSheet = async () => {
  //   let result = await getApprovedTimeSheet();
  //   setTimesheets(result.data);
  // }
  const loadUsersSearchPopup = () => {
    const profile = getProfile();
    if (profile.role !== "standard")
      setIsShowUsersPopup(!isShowUsersPopup);


  }
  const onClose = () => {
    setIsShowUsersPopup(!isShowUsersPopup);

  }
  const onCloseTimeSheet = () => {
    debugger;
    setIsShow(!isShow);
    getTimeSheet();
  }
  const search = (id, name) => {
    if (!id) {
      getTimeSheet(0);
      setSelectedUser('Select User');
    } else {
      getTimeSheet(id);
      setsearchedUserId(id);
      setSelectedUser(name);
    }
    setIsShowUsersPopup(!isShowUsersPopup);
  }
  const showEeventInfo = (arg) => { // bind with an arrow function
    setIsView(arg.event._def.extendedProps.status === "accepted" ? true : false);
    setTimeSheetId(Number(arg.event.id));
    setIsShow(!isShow);
  }
  const goToNextDay = () => {
    let calendarApi = ref.current.getApi();
    calendarApi.next();
    getTimeSheet(searchedUserId);
  }

  const goToPreviousDay = () => {
    let calendarApi = ref.current.getApi()
    calendarApi.prev();
    getTimeSheet(searchedUserId);
  }
  const getTimeSheet = async (id = 0) => {

    let calendarApi = ref.current.getApi();
    let result = await getApprovedTimeSheet(id, calendarApi.view.activeStart, calendarApi.view.activeEnd);
    setTimesheets(result.data);
  }

  return (<>
    <FullCalendar
      ref={ref}
      plugins={[interactionPlugin, timeGridPlugin, momentPlugin]}
      initialView="timeGridWeek"
      headerToolbar={{
        left: "prev,next,today,myCustomButton",
        center: "title",
        right: "timeGridDay,timeGridWeek",

      }}
      selectable='true'
      // dateClick={handleDateClick}
      select={showEeventInfo}
      customButtons={
        {
          myCustomButton: {
            text: selectedUser,
            click: () => {
              loadUsersSearchPopup();
            }
          },
          prev: { click: () => goToPreviousDay() },
          next: { click: () => goToNextDay() },
        }
      }
      dayHeaderFormat={(param) => {
        return moment(param.date).format("ddd DD/MM");
      }}
      titleFormat='DD/MM/YYYY'
      timeZone="UTC"
      height={ScreenHight}
      aspectRatio="1.5"
      events={
        timesheets
      }
      eventClick={showEeventInfo}
    >

    </FullCalendar>
    {
      isShowUsersPopup ? <><UserListPopup show={isShowUsersPopup} onClose={onClose} search={search} /></> : ""
    }
    <>
      {
        isShow ? <>
          <AddTimeSheetPopup isView={isView} isShow={isShow} id={timeSheetId} modelTitle={"Edit Time Sheet"} onClose={(e) => { onCloseTimeSheet() }} />
        </> : ''
      }
    </>
  </>);

});
export default Calendar;