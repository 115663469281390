import jwt_decode from "jwt-decode";
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
export function getMultipleOf(range,diff) {
    let rangeArryObj = [];
    for (let i=0;i< range;i++){
        if(i % diff===0){
            rangeArryObj.push(i);
        }
    }
    return rangeArryObj;
}
export function getProfile(){
    if(localStorage.getItem('_token')){
        const decoded = jwt_decode(localStorage.getItem('_token'));
        return {
            id:decoded.id,
            fullName:decoded.fullName,
            role:decoded.role,
            username:decoded.username
        };
    }
    return null;
    
}
export function DownloadExecl(csvData, fileName){
    const fileType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
    const fileExtension = '.xlsx';
    const ws = XLSX.utils.json_to_sheet(csvData);
    const wb = { Sheets: { 'data': ws }, SheetNames: ['data'] };
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });
    const data = new Blob([excelBuffer], {type: fileType});
    FileSaver.saveAs(data, fileName + fileExtension);
}
