import NevHeader from './../../shared/pages/nev-header';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { useState, useEffect } from 'react';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import moment from 'moment';
import {
    getUsersListDD,
    getClientsListDropDown,
    getReports,
    getReportsSummery,
    getAllSummaryRecords,
    generateAllReports
} from './../../httphandler/urlmapper';
import React from "react";
import { Download } from './downloadExcel';
import { DownloadExecl } from './../../utils/general';

const ManagReport = () => {

    useEffect(() => {
        loadUsersList();
        loadClientsList();
    }, [])


    const [usersList, setUsersList] = useState([]);
    const [clientsList, setClientList] = useState([]);
    var date = new Date();
    const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    const [clientId, setClientId] = useState();
    const [userId, setUserId] = useState();
    const [reportList, setReports] = useState([]);

    const [initialValues, setinitialValues] = useState({
        userId: '',
        clientId: '',
        startDate: '',
        endTime: ''
    });
    const validationSchema = yup
        .object()
        .shape({});
    const onSubmit = async (values) => {

    }
    const loadUsersList = async () => {
        const result = await getUsersListDD();
        if (result && result.statusCode === 200) {
            setUsersList(result.data);
        }
    }
    const loadClientsList = async () => {
            const clientsList = await getClientsListDropDown();
            if (clientsList && clientsList.statusCode === 200) {
                setClientList(clientsList.data);
            }
    }
    const getReportslsit = async () => {
        let result = await getReports({
            userId,
            clientId,
            startDate,
            endDate
        });
        if (result && result.statusCode === 200) {
            setReports(result.data);
        }
    }
    const getReportslsitSummery = async (id,cId) => {
        let result = await getReportsSummery({
            userId:id,
            clientId,
            startDate,
            endDate
        });
        if (result && result.statusCode === 200) {
            if(result.data && result.data.length >0){
                const reportsList = result.data.map((item,index)=>{
                    return {
                        "Sr #":index+1,
                        "Title":item.title,
                        "Client Name":item.clientName,
                        "User":item.fullName,
                        "Start":moment(item.start).format("DD/MM/YYYY"),
                        "End":moment(item.end).format("DD/MM/YYYY"),
                        "Hours":item.hours,
                        "Minutes":item.miniuts
                    }
                });
                DownloadExecl(reportsList,reportsList[0]['Client Name']+"_"+new Date(reportsList[0].Start).toDateString())
            }
        }
    }
    const exportAllSummary = async () => {
        let result = await getAllSummaryRecords({
            userId,
            clientId,
            startDate,
            endDate
        });
        if (result && result.statusCode === 200) {
            if(result.data && result.data.length >0){
                const reportsList = result.data.map((item,index)=>{
                    return {
                        "Sr #":index+1,
                        "Title":item.title,
                        "Client Name":item.clientName,
                        "User":item.fullName,
                        "Start":moment(item.start).format("DD/MM/YYYY"),
                        "End":moment(item.end).format("DD/MM/YYYY"),
                        "Hours":item.hours,
                        "Minutes":item.miniuts
                    }
                });
               DownloadExecl(reportsList,'All_Summary_'+new Date(startDate).toDateString()+'_to_'+new Date(endDate).toDateString())
              //console.table(reportsList);
            }
        }
    }
    const exportAll = async () => {
        let result = await generateAllReports({
            userId,
            clientId,
            startDate,
            endDate
        });
        if (result && result.statusCode === 200) {
            if(result.data && result.data.length >0){
                console.table(result.data);
                const reportsList = result.data.map((item,index)=>{
                    return {
                        "Sr #":index+1,
                        "Title":item.title,
                        "Client Name":item.clientName,
                        "User":item.fullName,
                        "Start":moment(item.start).format("DD/MM/YYYY"),
                        "End":moment(item.end).format("DD/MM/YYYY"),
                        "Total Hours":item.totalHours
                    }
                });
               DownloadExecl(reportsList,'All_'+new Date(startDate).toDateString()+'_to_'+new Date(endDate).toDateString())
              //console.table(reportsList);
            }
        }
    }
    return (
        <>
            <NevHeader routValues={{ pageRoute: '/reports', pageName: 'Manage Reports' }} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Quick Search</h3>
                                    <div className="card-tools">
                                        {/* <button onClick={(e) => { showAddUsersPopup() }} type="button" data-toggle="modal" data-target="#modal-lg"
                                        className="btn btn-tool" title="Add new use">
                                        Add <i className="fas fa-plus-circle"></i>
                                    </button> */}
                                    </div>
                                </div>

                                <div>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={async (values, { resetForm }) => {
                                            await onSubmit(values);
                                            resetForm();
                                        }}
                                        enableReinitialize={true}
                                    >
                                        <Form>
                                            <div className="modal-body">
                                                <div className="row">
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>Select Users</label>
                                                            <select onChange={(e) => setUserId(e.target.value)} as="select" name="userId" className="form-control" style={{ width: '100%' }}>
                                                                <option value="">All</option>
                                                                {
                                                                    usersList && usersList.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <option value={item.id}>{item.name}</option>
                                                                            </>);
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>Select Clients</label>
                                                            <select name="userId" className="form-control" onChange={(e) => { setClientId(e.target.value) }} style={{ width: '100%' }}>
                                                                <option value="All">All</option>
                                                                {
                                                                    clientsList && clientsList.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <option value={item.id}>{item.name}</option>
                                                                            </>);
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <Field
                                                                className="form-control"
                                                                name="startdate"
                                                                component={DatePicker}
                                                                dateFormat="dd/MM/yyyy"
                                                                label="Start date"
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <Field
                                                                className="form-control"
                                                                name="startdate"
                                                                component={DatePicker}
                                                                dateFormat="dd/MM/yyyy"
                                                                label="Start date"
                                                                selected={endDate}
                                                                onChange={(date) => setEndDate(date)}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label>_</label>
                                                        <div className="form-group">
                                                            <button type="button" onClick={ async (e) => { await getReportslsit() }} className="btn btn-primary"><i className='fa fa-search'></i></button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>_</label>
                                                        <div className="form-group">
                                                            <button title='Export All' type="button" onClick={(e) => { exportAll() }} className="btn btn"><i className='fa fa-download'></i> All Summary</button>
                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <label>_</label>
                                                        <div className="form-group">
                                                            <button title='Export All Summary' type="button" onClick={(e) => { exportAllSummary() }} className="btn btn"><i className='fa fa-download'></i> All Timesheets</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="container-fluid">
                        <div className="card-body table-responsive p-0">
                            <table className="table table-hover text-nowrap">
                                <thead>
                                    <tr>
                                        <th>Sr#</th>
                                        <th>Client Name</th>
                                        <th>User Name</th>
                                        <th>Start</th>
                                        <th>End</th>
                                        <th>Hours</th>
                                        <th>Minutes</th>
                                        <th>Total Hours</th>
                                        <th>Status</th>
                                        <th></th>
                                        {/* <th>Action: <button className="btn btn"><i class="fa fa-file-export"></i>  Export All</button></th> */}
                                    </tr>
                                </thead>
                                <tbody>
                                    {
                                        reportList && reportList.length > 0 ? reportList.map((item, index) => {
                                            return (<>
                                                <tr>
                                                    <td>{index + 1}</td>
                                                    <td>{item.clientName}</td>
                                                    <td>{item.fullName}</td>
                                                    <td>{moment(item.start).format("DD/MM/YYYY")}</td>
                                                    <td>{moment(item.end).format("DD/MM/YYYY")}</td>
                                                    <td>{item.hours}</td>
                                                    <td>{item.miniuts}</td>
                                                    <td>{item.totalHours} h</td>
                                                    <td style={{color:'green',textTransform:'Uppercase'}}>{item.status}</td>
                                                    <td>
                                                        <Download csvData={[{
                                                            "Sr #":index+1,
                                                            "Client Name":item.clientName,
                                                            "User":item.fullName,
                                                            "Start":moment(item.start).format("DD/MM/YYYY"),
                                                            "End":moment(item.end).format("DD/MM/YYYY"),
                                                            "Hours":item.hours,
                                                            "Minutes":item.miniuts,
                                                            "Total Hours":item.totalHours

                                                        }]} fileName={item.fullName+"_"+moment(item.start).format("DD/MM/YYYY")} btnName={'Export Summary'}></Download> ||
                                                         
                                                         <button className="btn btn" onClick={(e)=>{getReportslsitSummery(item.userId)}}><i class="fa fa-file-export"></i> Export Timesheets</button>
                                                    </td>
                                                </tr>

                                            </>);
                                        })
                                            : <></>}


                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}
export default ManagReport;

