export default async function axiosPromiseToHttpResponse(
  axiosPromise
) {
  return axiosPromise
    .then(({ data, status, statusText }) => {
      return {
        data,
        statusText,
        statusCode: status,
      };
    })
    .catch(({ response }) => {
      const { statusText, data } = response;
      const { status, message } = response;
      console.log("catch error", response)
      if(status===401 && window.location.href && window.location.href.indexOf('login') ===-1){
        localStorage.clear();
        window.location.href = '/login';
      }
      return (
        {
        data: undefined,
        status,
        statusText,
        message,
      }
      );
    });
}
