import Multiselect from 'multiselect-react-dropdown';
import { useState, useEffect } from 'react';
import {
    getClientsListDropDown,
    assignClientToUser,
    getClientsByUserId
} from '../../../httphandler/urlmapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AssingClientPopups = (props) => {

    useEffect(() => {
        getAllClientsList();
        getSelectedClientsList(props.data);
    }, [props]);
    const [clintsList, setClientList] = useState([]);
    const [selectedClients, setSelectedClients] = useState([]);

    const onSelect = (selectedList, selectedItem) => {
        setSelectedClients(selectedList)
    }
    const onRemove = (selectedList, removedItem) => {
        setSelectedClients(selectedList)
    }
    const getAllClientsList = async () => {
        const result = await getClientsListDropDown();
        if (result.data) {
            setClientList(result.data);
        }
    }
    const getSelectedClientsList = async (data) => {
        const result = await getClientsByUserId(data);
        if (result.statusCode === 200) {
            setSelectedClients([]);
            setSelectedClients(result.data);
        }
    }
    const addUpdateClient = async () => {
        const result = await assignClientToUser({ userId: props.data, clients: [...selectedClients] });
        if (result.statusCode === 200) {
            toast.success('Request successfully updated.')
        }
    }
    return (
        <>
            <div className="modal fade" id="modal-clients" aria-hidden="true">
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{props.modelTitle}</h4>
                            <button onClick={props.onClose} type="button" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="row">
                                <div className="col-md-12" data-select2-id="30">
                                    <div className="form-group" data-select2-id="29">
                                        <label>Select Client</label>
                                        <div className="select2-purple">
                                            <Multiselect
                                                options={clintsList} // Options to display in the dropdown
                                                selectedValues={selectedClients} // Preselected value to persist in dropdown
                                                onSelect={onSelect} // Function will trigger on select event
                                                onRemove={onRemove} // Function will trigger on remove event
                                                displayValue="name" // Property name to display in the dropdown options
                                            />


                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>
                        <div className="modal-footer justify-content-between">
                            <button type="button" className="btn btn-default" onClick={props.onClose} data-dismiss="modal">Close</button>
                            <button onClick={async (e) => { await addUpdateClient() }} type="button" className="btn btn-primary">Update changes</button>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </>);
}
export default AssingClientPopups;