import { NavLink } from 'react-router-dom';
const NevHeader = (props) => {
    
    
    return (
        <>
            <section className="content-header">
                <div className="container-fluid">
                    <div className="row mb-2">
                        <div className="col-sm-6">
                            <h1>{props.routValues.pageName}</h1>
                        </div>
                        <div className="col-sm-6">
                            <ol className="breadcrumb float-sm-right">
                                <li className="breadcrumb-item"><NavLink to="/">Home</NavLink></li>
                                {
                                    props.routValues ? <><li className="breadcrumb-item active"><NavLink to="{props.routValues.pageRoute}"></NavLink> {props.routValues.pageName}</li></>:""
                                }
                            </ol>
                        </div>
                    </div>
                </div>
            </section>
        </>);
}
export default NevHeader;