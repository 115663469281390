/* eslint-disable no-unused-vars */
import { useState } from 'react';
import { useEffect } from 'react';
import NevHeader from './../../shared/pages/nev-header';
import LocationPopup from './locations/locations-popup';
import WorkTypePopup from './worktype/worktype-popup';
/* eslint-disable jsx-a11y/anchor-is-valid */


const Settings = () => {
    const [isShowLocation, setIsShowLocation] = useState(false);
    const [isShowWorkType, setIsShowWorkType] = useState(false);

    useEffect(() => {
        
    }, []);
   
   const onClose =()=>{
    setIsShowLocation(!isShowLocation);
    setIsShowWorkType(!isShowWorkType);
   }
    return (
        <>
            <NevHeader routValues={{ pageRoute: '/settings', pageName: 'Manage Settings' }} />
            <section className="content">
                <div className="container-fluid">
                <div className="row">
                     
                        <div className="col-md-12">
                            <div className="card">
                                {/* <div className="card-header">
                                
                                    <div className="card-tools">
                                       
                                    </div>
                                </div>  */}
                                <div className="card-body table-responsive p-0">
                                    <br/>
                                    <ul>
                                        <li><a href="#" onClick={(e)=>setIsShowLocation(!isShowLocation)} data-toggle="modal" data-target="#modal-lg-location">Locations</a></li>
                                        <li><a href="#" onClick={(e)=>setIsShowWorkType(!isShowWorkType)} data-toggle="modal" data-target="#modal-lg-workType">WorkType</a></li>
                                    </ul>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
          {
            isShowLocation ? 
            <>
                <LocationPopup onClose={onClose}></LocationPopup>
            </>:""
          }
           {
            isShowWorkType ? 
            <>
                <WorkTypePopup onClose={onClose}></WorkTypePopup>
            </>:""
          }
        </>);

}
export default Settings;