import axios from 'axios';
import axiosPromiseToHttpResponse from './transforms/axios-http-response';
const BASE_URL = window.location.href.indexOf('trp') >-1 || window.location.href.indexOf('workview') >-1
?window.location.origin+"/api/"
:"http://localhost:8081/"
axios.defaults.baseURL = BASE_URL;//process.env.REACT_APP_API_URL_LOCAL;
export async function doGet(path, params = {}) {
  const token = localStorage.getItem('_token');
  const axiosPromise = axios({
    method: 'GET',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      'Authorization': `Bearer ${token}`,
    },
    params,
    url: path,
  });

  return axiosPromiseToHttpResponse(axiosPromise);
}

export async function doPost(
  path="",
  body = {},
  params = {},
  hasFile = false
){
    const token = localStorage.getItem('_token');
  const axiosPromise = axios({
    method: 'POST',
    headers: {
      'Content-Type': hasFile ? "multipart/form-data" : 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      Authorization: `Bearer ${token}`,
    },
    url: path,
    data: body,
    params,
  });

  return axiosPromiseToHttpResponse(axiosPromise);
}

export async function doPatch(
  path="",
  body = {},
  params = {}
) {
    const token = localStorage.getItem('_token');
  const axiosPromise = axios({
    params,
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json; charset=utf-8',
      'Access-Control-Allow-Origin': '*',
      Authentication: `Bearer ${token}`,
    },
    url: path,
    data: body,
  });

  return axiosPromiseToHttpResponse(axiosPromise);
}

// export async function doDelete(path: string, params = {}) {
//   const token = getToken();
//   const axiosPromise = axios({
//     method: 'DELETE',
//     headers: {
//       'Content-Type': 'application/json; charset=utf-8',
//       'Access-Control-Allow-Origin': '*',
//       Authentication: `Bearer ${token}`,
//       params
//     },
//     url: path,
//   });

//   return axiosPromiseToHttpResponse(axiosPromise);
//}
