/* eslint-disable jsx-a11y/anchor-is-valid */

import { useEffect, useState } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {
    getAllLocationDD,
    addUpdateLocations

} from '../../../httphandler/urlmapper';
const LocationPopup = (props) => {
    const [locationsList, setLocationList] = useState([]);
    const [locationStatus, setLocationStatus] = useState(true);
    useEffect(() => {
        getLocationDD();
    }, []);
    const [initialValues, setinitialValues] = useState({
        id: 0,
        locationName: '',
        retired: true,

    });
    const validationSchema = yup
        .object()
        .shape(
            {
                locationName: yup.string().required('Please type location name.')
            });
    const onLocationStatus = (event)=> {
        setLocationStatus(event.target.checked);
    }
    const onSubmit = async (values) => {
        values.retired = !values.retired;
        const result = await addUpdateLocations(values);
        if(result.statusCode === 200){
            toast.success("Your request has been submited ");
            await getLocationDD();
        }else if(result.status===409) {
            toast.error('Location already exist.')
        }
        resetFormEvent();
    }
    const getLocationDD = async () => {
        const result = await getAllLocationDD();
        if (result.statusCode === 200) {
            setLocationList(result.data);
        }
    }
    const editLocation = async (obj) =>{
        //setinitialValues({});
        setLocationStatus(!obj.retired);
        setinitialValues({
            id:obj.id,
            locationName:obj.locationName,
            retired:!obj.retired
        });
    }
    const resetFormEvent = ()=> {
        debugger;
        setinitialValues({
            id: 0,
            locationName: '',
            retired: true,
        });
        setLocationStatus(true);
    }
    const onClose = ()=>{
        document.getElementById('clickToClose').click();
        props.onClose();
    }
    const renderError = (message) => <p className="help is-danger">{message}</p>
    return (<>
        <div className="modal fade" id="modal-lg-location" aria-hidden="true">
            <div className="modal-dialog modal-lg">
                <div className="modal-content">
                    <div className="modal-header">
                        <h4 className="modal-title">Manage Locations</h4>
                        <button type="button" className="close" data-dismiss="modal" id="clickToClose" onClick={((e)=>onClose())} aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                    <div className="modal-body">
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { resetForm }) => {
                                await onSubmit(values);
                                 resetForm();
                            }}
                            enableReinitialize={true}
                        >
                            <Form>
                                <div className="row">
                                    <div className="col-md-6" data-select2-id="30">
                                        <div className="form-group" data-select2-id="29">

                                            <Field type="text" name="locationName" placeholder="Location name" className="form-control" />
                                            <ErrorMessage name="locationName" render={renderError} />
                                        </div>

                                    </div>
                                    <div className="col-md-2" data-select2-id="30">
                                        <div className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                            <Field onClick={onLocationStatus} type="checkbox" name="retired" className="custom-control-input" id="customSwitch3" />
                                            <label  className="custom-control-label" htmlFor="customSwitch3">
                                                {
                                                    locationStatus ? 
                                                    <span style={{color:'green'}}>Active</span>:
                                                    <span style={{color:'red'}}>Inactive</span>
                                                } </label>
                                        </div>
                                    </div>
                                    <div className="col-md-4">
                                        <div className="form-group">
                                            <button type="submit" className="btn btn-primary">
                                                {
                                                    initialValues && initialValues.id >0 ? <>Update</>:<>+</>
                                                }
                                            </button> | <button type="button" className="btn btn-primary" onClick={(e)=>resetFormEvent()}>Reset</button>
                                        </div>
                                    </div>
                                </div>
                            </Form>

                        </Formik>
                        <div className="row">
                            <div className="col-md-12">
                                <table className="table table-hover text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>Loc Code</th>
                                            <th>Loc Name</th>
                                            <th>Status</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            locationsList && locationsList.length >0 && locationsList.map((item, index) => {
                                                return (
                                                    <>
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{item.locationName}</td>
                                                            <td>
                                                                {
                                                                    item.retired ? <><span style={{ color: 'red' }} className="text-danger"> Inactive

                                                                    </span></> : <><span style={{ color: 'green' }}>Active</span></>
                                                                }
                                                            </td>
                                                            <td>
                                                                <div className="margin">
                                                                    <div className="btn-group">
                                                                        <button type="button" className="btn btn-default">Action</button>
                                                                        <button type="button"
                                                                            className="btn btn-default dropdown-toggle dropdown-icon"
                                                                            data-toggle="dropdown">
                                                                        </button>
                                                                        <div className="dropdown-menu" role="menu">
                                                                            <a onClick={(e)=>{editLocation(item)}} className="dropdown-item" href="#"><i className="fa fa-edit"></i>
                                                                                Edit</a>

                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </>)
                                            })
                                        }



                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>

                </div>

            </div>
        </div>
        <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            <ToastContainer />
    </>)
}
export default LocationPopup;