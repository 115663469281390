import { Button, Modal } from 'react-bootstrap';
import { getUsersListDD } from './../../../httphandler/urlmapper';
import { useState, useEffect } from 'react';
const UserListPopup = (props) => {

    const [usersList, setUsersList] = useState([]);
    const [selectedUserId, setSelectedUserId] = useState();
    const [selectedUser, setSelectedUser] = useState();

    useEffect(() => {
        loadUsersList();
    }, [])
    const onClose = () => {
        props.onClose();
    }
    const loadUsersList = async () => {
        const result = await getUsersListDD();
        if (result && result.statusCode === 200) {
            setUsersList(result.data);
        }
    }
    const search = (event)=>{
        
        setSelectedUser(event.target.options[event.target.selectedIndex].innerText);
        setSelectedUserId(event.target.value);
       
    }
    return (<>
        <Modal show={props.show} onHide={() => onClose()}>
            <Modal.Header>Select Users
                <button onClick={props.onClose} type="button" id="closeFromid" className="close" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true">×</span>
                </button>
            </Modal.Header>
            <Modal.Body>
                <div className="row">
                    <div className="col-md-12">
                        <div className="form-group">
                            <label>Select Users</label>
                            <select onChange={search} name="userId" className="form-control" style={{ width: '100%' }}>
                                <option value="">You</option>
                                {
                                    usersList && usersList.map((item, index) => {
                                        return (
                                            <>
                                                <option value={item.id}>{item.name}</option>
                                            </>);
                                    })
                                }
                            </select>

                        </div>
                    </div>
                </div>
            </Modal.Body>
            <Modal.Footer>
                <Button onClick={() => onClose()}>Close</Button>
                <Button onClick={() =>  props.search(selectedUserId, selectedUser)}>Search</Button>
            </Modal.Footer>
        </Modal>

    </>)
}

export default UserListPopup;