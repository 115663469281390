import { Route, Routes ,Navigate} from "react-router-dom"
import Layout from "../shared/lib/layout";
import Login from './../components/login/login-com';
import Dashboard from './../components/dashbord/dashboard-com';
import ManageUsers from './../components/users/users-com';
import ManageClient from '../components/clients/client-com';
import ManageTimeSheet from './../components/timesheets/manage-time-sheet-com';
import Settings from './../components/settings/settings-comp';
import ManagReport from './../components/reports/reports-com';
import { getProfile } from "../utils/general";
import OrganizationComponent from './../components/organization/organization-com';
import { RequireAuth } from "./requiredAuth";
import ForgotPassword from "../components/login/forgot-password";
const ROLES = {
    User: "standard",
    Manager: "manager",
    Admin: "admin",
};
export const Router = () => {

    const profile = getProfile()
    return (

        <Routes>
            <Route path="/login" element={<Login />} />
            {/* <Route path="*" element={<Layout><Dashboard /></Layout>} /> */}
                
                <Route
                path="/"
                element={
                    <RequireAuth roles={['admin','manager','standard']}>
                        <Layout>
                            <Dashboard />
                        </Layout>
                    </RequireAuth>
                } />
               
            <Route
                path="/dashboard"
                element={
                    <RequireAuth roles={['admin','manager','standard']}>
                        <Layout>
                            <Dashboard />
                        </Layout>
                    </RequireAuth>
                } />
                
                
                <Route
                path="/timesheets"
                element={
                    <RequireAuth roles={['admin','manager','standard']}>
                        <Layout>
                            <ManageTimeSheet />
                        </Layout>
                    </RequireAuth>
                } />


                <Route
                path="/users"
                element={
                    <RequireAuth roles={['admin','manager']}>
                        <Layout>
                            <ManageUsers />
                        </Layout>
                    </RequireAuth>
                } />


                <Route
                path="/clients"
                element={
                    <RequireAuth roles={['admin','manager']}>
                        <Layout>
                            <ManageClient />
                        </Layout>
                    </RequireAuth>
                } />
                
                
                <Route
                path="/settings"
                element={
                    <RequireAuth roles={['admin','manager']}>
                        <Layout>
                            <Settings />
                        </Layout>
                    </RequireAuth>
                } />

                <Route
                path="/reports"
                element={
                    <RequireAuth roles={['admin','manager']}>
                        <Layout>
                            <ManagReport />
                        </Layout>
                    </RequireAuth>
                } />
                
                <Route
                path="/organizations"
                element={
                    <RequireAuth roles={['super admin']}>
                        <Layout>
                            <OrganizationComponent />
                        </Layout>
                    </RequireAuth>
                } />
                <Route
                path="/forgot-password"
                element={<ForgotPassword></ForgotPassword>} />
            {/* <Route path="/" element={<Layout><Dashboard /></Layout>} />
            <Route roles={['admin,manager,standard']} path="/timesheets" element={<Layout><ManageTimeSheet /></Layout>} />

            <Route roles={['admin,manager']} path="/users" element={<Layout><ManageUsers /></Layout>} />
            <Route roles={['admin,manager']} path="/clients" element={<Layout><ManageClient /></Layout>} />

            <Route roles={['admin,manager']} path="/settings" element={<Layout><Settings /></Layout>} />
            <Route roles={['admin,manager']} path="/reports" element={<Layout><ManagReport /></Layout>} /> */}








        </Routes>
    )
}