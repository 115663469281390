/* eslint-disable jsx-a11y/anchor-is-valid */
import NevHeader from '../../shared/pages/nev-header';
import { useState } from 'react';
import { useEffect } from 'react';
import AddUpdateClientPopup from './popups/addupdateclient-popups';
import { getAllClients } from './../../httphandler/urlmapper';
import { styles } from './../../shared/css/stylecss';
const ManageClient = () => {
    const [isShow, setIsShow] = useState(false);
    useEffect(() => {
        getAllClientsList();
    }, []);
    const [clientList, setClientList] = useState([]);
    const [clientData, setClientData] = useState({});
    const [searchClientData, setSearchClientData] = useState({});
    const showAddUsersPopup = (data={}) => {
        setClientData(data)
        setIsShow(!isShow);
    }
    const onClose = () => {
        setIsShow(!isShow);
        getAllClientsList();
    }
    const getAllClientsList = async () => {
        const result = await getAllClients();
        setClientList(result.data);
        setSearchClientData(result.data);
    }
    const search = (value) => {
        if (value) {
            setSearchClientData(clientList.filter((x =>x.clientCode.includes(value) || x.clientName.includes(value) ))); 
        }else {
            setSearchClientData(clientList);
        }
    }
    return (
        <>
            <NevHeader routValues={{ pageRoute: '/clients', pageName: 'Manage Client' }} />
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                    <div className="col-md-1"></div>
                        <div className="col-md-10">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Quick Search <input onChange={(e)=>{search(e.target.value)}} style={styles.carsearchFiled}></input></h3>
                                    <div className="card-tools">
                                        <button type="button" data-toggle="modal" data-target="#modal-lg" onClick={(e) => { showAddUsersPopup() }}
                                            className="btn btn-tool" title="Add new client">
                                            Add <i className="fas fa-plus-circle"></i>
                                        </button>
                                    </div>
                                </div>

                                <div>
                                    {/* <form>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <div className="input-group input-group-sm">
                                                    <input placeholder="Search by name" type="text"
                                                        className="form-control" />
                                                    <span className="input-group-append">
                                                        <button type="button"
                                                            className="btn btn-info btn-flat">Search!</button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form> */}
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>Sr#</th>
                                                <th>Client ID</th>
                                                <th>Client Name</th>
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                searchClientData && searchClientData.length > 0 ? searchClientData.map((item, index) => {
                                                    return (<>
                                                        <tr>
                                                            <td>{index+1}</td>
                                                            <td>{item.clientCode}</td>
                                                            <td>{item.clientName}</td>
                                                            <td>{
                                                                    item.retired ? <><span style={{ color: 'red' }}>Inactive</span></> :
                                                                        <><span style={{ color: 'green' }}>Active</span></>
                                                                }</td>
                                                            <td>
                                                                <div className="margin">
                                                                    <div className="btn-group">
                                                                        <button type="button"
                                                                            className="btn btn-default">Action</button>
                                                                        <button type="button"
                                                                            className="btn btn-default dropdown-toggle dropdown-icon"
                                                                            data-toggle="dropdown">
                                                                        </button>
                                                                        <div data-toggle="modal" data-target="#modal-lg" className="dropdown-menu" role="menu">
                                                                            <a onClick={(e) => { showAddUsersPopup(item) }} className="dropdown-item" href="#"><i
                                                                                className="fa fa-edit"></i> Edit</a>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>

                                                    </>);
                                                })
                                                    : <></>}


                                        </tbody>
                                    </table>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <>
                {
                    isShow ?
                        <AddUpdateClientPopup data={clientData} modelTitle={"Update"} onClose={onClose}></AddUpdateClientPopup> : ""
                }
            </>
        </>
    );
}
export default ManageClient;