import { Formik, Form, Field, ErrorMessage } from "formik";
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { addClientRequest,updateClientRequest} from '../../../httphandler/urlmapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddUpdateClientPopup = (props) => {

    useEffect(() => {
        if(props.data){
            setUsersStatus(!props.data.retired);
        }
    },[props]);
    const [usersStatus,setUsersStatus] = useState(true);
    const [initialValues, setinitialValues] = useState({
        id:props?.data?.id ? props?.data?.id :null,
        clientName: props?.data?.clientName ? props?.data?.clientName :'',
        retired : props?.data?.retired ? false :true,
        clientCode : props?.data?.clientCode ? props?.data?.clientCode:'' 
    });
    const validationSchema = yup.object().shape({

        clientName: yup.string().required("Please add client name"),
        clientCode: yup.string().required("Please add client code")
    });
    const onSubmit = async (values) => {
        debugger;
        values.retired = !values.retired;
        let result = props?.data?.clientCode ? await updateClientRequest(values) : await addClientRequest(values);
        if (result && result.status === 409) {
            toast.error('Client name or client id already exist.')
        }else if(result && result.statusCode === 200){
            toast.success('Client successfully updated.')
        }
    }
    const onUsersStatus = (event)=> {
        setUsersStatus(event.target.checked);
    }
    const renderError = (message) => <p className="help is-danger">{message}</p>
    return (
        <>
            <div className="modal fade" id="modal-lg" aria-hidden="true" style={{ display: 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{props.modelTitle ? props.modelTitle : "Model"}</h4>
                            <button onClick={props.onClose} type="button" className="close" id="clickToClose" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { resetForm }) => {
                                await onSubmit(values);
                                if (props.data.id) {
                                    document.getElementById('clickToClose').click();
                                }
                                else
                                    resetForm();
                            }}

                        >
                            <Form>
                                <div className="modal-body">
                                    <div className="row">
                                       {/* {
                                         props.data.id ? <>
                                         
                                         </>:
                                         ""
                                       } */}
                                        <div className="col-md-3" data-select2-id="30">
                                            <div className="form-group" data-select2-id="29">
                                                <label>Client id</label>
                                                <Field type="text" name="clientCode" className="form-control" />
                                                <ErrorMessage name="clientCode" render={renderError} />
                                                {/* <input value={props.data.clientCode}  type="text" className="form-control" /> */}
                                            </div>
                                        </div>
                                        <div className="col-md-6" data-select2-id="30">
                                            <div className="form-group" data-select2-id="29">
                                                <label>Client Name</label>
                                                <Field type="text" name="clientName" className="form-control" />
                                                <ErrorMessage name="clientName" render={renderError} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Status</label>
                                                <div
                                                    className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                      <Field onClick={onUsersStatus} type="checkbox" name="retired" className="custom-control-input" id="status101" />
                                                        <label className="custom-control-label" htmlFor="status101">{
                                                                usersStatus ? <span style={{color:'green'}}>Active</span>:
                                                                <span style={{color:'red'}}>Inactive</span>
                                                            }</label>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button onClick={props.onClose} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                    <button type="submit" className="btn btn-primary">Update changes</button>
                                </div>
                            </Form>
                        </Formik>
                        </>
                    </div>

                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </>);
}

export default AddUpdateClientPopup;