export const styles = {
    timeSheetButtonStyle: {
        width: '100px',
        height: '100px',
        position: 'fixed',
        bottom: '5%',
        right: '5%',
        'border-radius': '50%',
        border: 'none',
        background:'var(--tdb-blue)',
        color: 'var(--apple-darkgray)',
        'font-size': '5rem',
        'z-index': '1'
    },
    carsearchFiled:{
        padding: '5px',
        border: '2px solid #eee',
        boxShadow:'0 0 15px 4px rgba(0,0,0,0.06)'
    }
  }