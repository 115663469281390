/* eslint-disable jsx-a11y/anchor-is-valid */
const WidgetTimeSheetCount = (props) => {
   
    return (
        <>
            <div className="row">
                <div className="col-lg-3 col-6">

                    <div className="small-box bg-info">
                        <div className="inner">
                            <h3>{props.data.totalRequests}</h3>

                            <p>Saved Request</p>
                        </div>
                        {/* <div className="icon">
                            <i className="fas fa-shopping-cart"></i>
                        </div> */}
                        <a href="#" onClick={(e)=>props.loadStatusWise('saved')}  className="small-box-footer">
                            More info <i className="fas fa-arrow-circle-down"></i>
                        </a>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="small-box bg-success">
                        <div className="inner">
                        <h3>{props.data.totalApproved}</h3>

                            <p>Approved</p>
                        </div>
                        {/* <div className="icon">
                            <i className="ion ion-stats-bars"></i>
                        </div> */}
                        <a href="#" onClick={(e)=>props.loadStatusWise('accepted')} className="small-box-footer">
                            More info <i className="fas fa-arrow-circle-down"></i>
                        </a>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="small-box bg-warning">
                        <div className="inner">
                        <h3>{props.data.totalPeending}</h3>

                            <p>Pending</p>
                        </div>
                        {/* <div className="icon">
                            <i className="fas fa-user-cros"></i>
                        </div> */}
                        <a href="#" onClick={(e)=>props.loadStatusWise('submitted')} className="small-box-footer">
                            More info <i className="fas fa-arrow-circle-down"></i>
                        </a>
                    </div>
                </div>
                <div className="col-lg-3 col-6">
                    <div className="small-box bg-danger">
                        <div className="inner">
                        <h3>{props.data.totalRejected}</h3>

                            <p>Rejected</p>
                        </div>
                        {/* <div className="icon">
                            <i className="fas fa-chart-pie"></i>
                        </div> */}
                        <a href="#" onClick={(e)=>props.loadStatusWise('rejected')} className="small-box-footer">
                            More info <i className="fas fa-arrow-circle-down"></i>
                        </a>
                    </div>
                </div>
            </div>

        </>);
}
export default WidgetTimeSheetCount;