import * as yup from 'yup';
import {useEffect,useState,useRef} from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { addUsersRequest,updateUsersRequest } from '../../../httphandler/urlmapper';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const AddUpdateUserPopup = (props) => {
    
    
    useEffect(() => {
        if(props.data && props.data.id){
            
            setUsersStatus(props.data.is_active);
        }
    },[props]);
    const [usersStatus,setUsersStatus] = useState(true);
    const [initialValues, setinitialValues] = useState({
        id:props.data.id ? props.data.id :null,
        fullName: props.data.fullName ? props.data.fullName :'',
        role:  props.data.role ? props.data.role :'',
        password: 'admin@123',
        is_active : props.data.id ? props.data.is_active:true,
        username: props.data.username ? props.data.username :''
    });
    const validationSchema = yup.object().shape({
        //password: yup.string().required("Please enter password."),
        fullName: yup.string().required("Please enter full name").max(50, 'Please enter name between 1 to 50 characters'),
        role: yup.string().required("Please select role"),
        username: yup.string().email().required("Please enter email address."),
    });
    const onSubmit = async (values) => {
        let result = props.data.id ? await updateUsersRequest(values) : await addUsersRequest(values);
        
        if (result && result.status === 409) {
            toast.error('Email or username already exist.')
        }else if (result && result.status ===403){
            toast.error('Maximum users registration request exceeded.')
        }
        else if(result && result.statusCode === 200){
            toast.success('Users successfully updated.')
        }
    }
    const onUsersStatus = (event)=> {
        setUsersStatus(event.target.checked);
    }
   
    const renderError = (message) => <p className="help is-danger">{message}</p>
    return (
        <>
            <div className="modal fade" id="modal-lg" aria-hidden="true" style={{ display: 'none' }}>
                <div className="modal-dialog modal-lg">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{props.modelTitle ? props.modelTitle : "Model"}</h4>
                            <button onClick={(e) => { props.onClose(); props.getAllUsrsListEvent() }} type="button" className="close" id="clickToClose" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <>
                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={async (values, { resetForm }) => {
                                    await onSubmit(values);
                                    if(props.data.id)
                                    {
                                      document.getElementById('clickToClose')
                                    }
                                    else
                                    resetForm();
                                }}
                               
                            >
                                <Form>
                                    <div className="modal-body">
                                        <div className="row">
                                            <div className="col-md-6" data-select2-id="30">
                                                <div className="form-group" data-select2-id="29">
                                                    <label>Full Name</label>
                                                    <Field type="text" name="fullName" className="form-control" />
                                                    <ErrorMessage name="fullName" render={renderError} />
                                                </div>

                                            </div>

                                            <div className="col-md-4" data-select2-id="30">
                                                <div className="form-group" data-select2-id="29">
                                                    <label>Role</label>
                                                    <Field as="select" name="role" className="form-control" style={{ width: '100%' }}
                                                        data-select2-id="1" tabindex="-1" aria-hidden="true">
                                                        <option selected="selected" data-select2-id="3">Select Role</option>
                                                        <option selected="selected" data-select2-id="3">admin</option>
                                                        <option data-select2-id="36">manager</option>
                                                        <option data-select2-id="37">standard</option>
                                                    </Field>
                                                    <ErrorMessage name="role" render={renderError} />
                                                </div>
                                            </div>
                                            <div className="col-md-2">
                                                <div className="form-group">
                                                    <label>Status</label>
                                                    <div
                                                        className="custom-control custom-switch custom-switch-off-danger custom-switch-on-success">
                                                        <Field onClick={onUsersStatus} type="checkbox" name="is_active" className="custom-control-input" id="status101" />
                                                        <label className="custom-control-label" htmlFor="status101">
                                                            {
                                                                usersStatus ? <span style={{color:'green'}}>Active</span>:
                                                                <span style={{color:'red'}}>Inactive</span>
                                                            }
                                                        </label>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-md-12" data-select2-id="30">
                                                <div className="form-group" data-select2-id="29">
                                                    <label>Email</label>
                                                    <Field type="email" name="username" className="form-control" id="emailaddress" />
                                                    <ErrorMessage name="username" render={renderError} />
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="modal-footer justify-content-between">
                                        <button onClick={(e) => { props.onClose(); props.getAllUsrsListEvent() }} type="button" className="btn btn-default" data-dismiss="modal">Close</button>
                                        <button type="submit" className="btn btn-primary">Update changes</button>
                                    </div>
                                </Form>
                            </Formik>
                        </>


                    </div>

                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </>);
}

export default AddUpdateUserPopup;