import { NavLink } from 'react-router-dom';
import { useState } from 'react';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as yup from 'yup';
import { loginRequest } from '../../httphandler/urlmapper';
import { useNavigate } from "react-router-dom";
import jwt_decode from "jwt-decode";
import useProfile from '../../hooks/useProfile';
import { sendForgotPasswordEmail } from './../../httphandler/urlmapper';
const ForgotPassword = () => {
    let navigate = useNavigate();
    const [errorMessage, setErrorMessage] = useState('');
    const [Message, setMessage] = useState('');
    const [isLoooding, setIsLooding] = useState(false);
    const { profile, setProfile } = useProfile();
    const validationSchema = yup.object().shape({
        email: yup.string().email().required("Please enter email address.")
    });
    const initialValues = {
        email: ''
    };
    const onSubmit = async (values) => {
        setIsLooding(true);
        setErrorMessage('');
        const result = await sendForgotPasswordEmail(values.email);
        setIsLooding(false);
        if (result && result.status === 404) {
            setErrorMessage("Please type a valid email address.");
        } else {
            setMessage("Rest password email sent to your email.");
            setTimeout(() => {
                navigate('/login', { replace: true })
            }, 2000);
        }
    }

    const renderError = (message) => <p className="help is-danger">{message}</p>
    return (
        <>
            <div className=' login-page'>
                <div className="login-box">
                    <div className="login-logo">
                        <NavLink to="/"> <img width='200' src="dist/img/trp_logo.png" alt="" className="brand-image" style={{ opacity: '.8' }} /></NavLink>
                    </div>

                    <div className="card">
                        <div className="card-body login-card-body">
                            <p className="login-box-msg">Type your email to rest password.</p>

                            <Formik
                                initialValues={initialValues}
                                validationSchema={validationSchema}
                                onSubmit={async (values, { resetForm }) => {
                                    await onSubmit(values);
                                    resetForm();
                                }}

                            >
                                <Form>
                                    <div className="input-group mb-3">
                                        <Field type="email" name="email" className="form-control" placeholder="Email" />

                                        <div className="input-group-append">
                                            <div className="input-group-text">
                                                <span className="fas fa-envelope"></span>
                                            </div>
                                        </div>

                                    </div>
                                    <ErrorMessage name="email" render={renderError} />
                                    <div className="row">
                                        <div style={{ color: 'red' }}>
                                            {errorMessage}
                                            <br></br>
                                        </div>
                                        <div style={{ color: 'green' }}>
                                            {Message}
                                            <br></br>
                                        </div>
                                        <div className="col-12">
                                            <button disabled={isLoooding} type="submit" className="btn btn-primary btn-block">
                                                {
                                                    !isLoooding ? <>Reset Password.</> : <div class="spinner-border text-dark" role="status">
                                                        <span class="sr-only">Loading...</span>
                                                    </div>
                                                }

                                            </button>
                                        </div>
                                    </div>
                                </Form>
                            </Formik>
                            <NavLink to='/login' className="mb-1">
                                <p>
                                    Back to login
                                </p>
                            </NavLink>
                        </div>

                    </div>
                    <br></br>
                    <div className="login-logo-buttom">
                        <p>Copyright @{new Date().getFullYear()} Powered by  <b>The Data Business</b> </p>
                    </div>
                </div>
            </div>

        </>)
}

export default ForgotPassword;