/* eslint-disable jsx-a11y/anchor-is-valid */
import NevHeader from './../../shared/pages/nev-header';
import WidgetTimeSheetCount from './lib/widget-timesheet-cout';
import { useState } from 'react';
import { useEffect } from 'react';
import AddTimeSheetPopup from './../dashbord/popups/addtimesheet-popus';
import moment from 'moment';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
import { getProfile } from '../../utils/general';
import {
    deleteTimeSheet,
    getAllTimeSheetsByStatus,
    getClientsListDropDown,
    getTimeSheetCount,
    getUsersListDD
} from '../../httphandler/urlmapper'
const ManageTimeSheet = () => {
    var date = new Date();
    const [isShow, setIsShow] = useState(false);
    const [isView, setIsView] = useState(false);
    const [timeSheetList, setTimeSheetList] = useState([]);
    const [timeSheetId, setTimeSheetId] = useState(0);
    const [timeSheetCounts, setTimeSheetCounts] = useState({});
    const [timeSheetStatus, setTimeSheetStatus] = useState('submitted');
    const [usersList, setUsersList] = useState([]);
    const [clientsList, setClientList] = useState([]);
    const [startDate, setStartDate] = useState(new Date(date.getFullYear(), date.getMonth(), 1));
    const [endDate, setEndDate] = useState(new Date(date.getFullYear(), date.getMonth() + 1, 0));
    const [clientId, setClientId] = useState();
    const [userId, setUserId] = useState();
    const [pageNo, setPageNo] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [totalSize, setTotalSize] = useState(0);
    const profile = getProfile()
    console.log(profile);
    
    useEffect(() => {
        loadTimeSheet();
        loadTimeSheetCounts();
        loadUsersList();
        loadClientsList();
    }, []);
    const [initialValues, setinitialValues] = useState({
        userId: '',
        clientId: '',
        startDate: '',
        endTime: ''
    });
    useEffect(() => {
        loadTimeSheet();
    }, [pageNo]);
    useEffect(() => {
        loadTimeSheet();
    }, [timeSheetStatus]);
    const loadTimeSheet = async () => {
        setTimeSheetList([]);
        const result = await getAllTimeSheetsByStatus(timeSheetStatus,userId,clientId,moment(startDate).format("YYYY-MM-DD"),moment(endDate).format("YYYY-MM-DD"),pageNo,pageSize);
        if (result.statusCode === 200) {
            setTimeSheetList(result.data.result);
            setTotalSize(result.data.Total[0].Total);
        }
    }
    const loadTimeSheetCounts = async () => {
        const result = await getTimeSheetCount(
            {
                userId: userId,
                clientId: clientId,
                start: moment(startDate).format("YYYY-MM-DD"),
                end: moment(endDate).format("YYYY-MM-DD")
            }
        );
        if (result.statusCode === 200) {
            setTimeSheetCounts(result.data);
        }
    }
    const onClose = () => {
        setIsShow(!isShow);
        setIsShow(false);
        setIsShow(false);
        loadTimeSheet();
        loadTimeSheetCounts();
    }
    const removeTimeSheet = async (id) => {
        const result = await deleteTimeSheet(id);
        if (result.statusCode === 200) {
            toast.success("Time sheet removed successfully.");
            loadTimeSheet();
            loadTimeSheetCounts();
        }
    }
    const validationSchema = yup
        .object()
        .shape({});
    const onSubmit = async (values) => {

    }
    const loadUsersList = async () => {
        const result = await getUsersListDD();
        if (result && result.statusCode === 200) {
            setUsersList(result.data);
        }
    }
    const loadClientsList = async () => {
            const clientsList = await getClientsListDropDown();
            if (clientsList && clientsList.statusCode === 200) {
                setClientList(clientsList.data);
            }
    }
    const searchTimeSheet=()=>{
        setTimeout(() => {
            loadTimeSheet();
        }, 1000);
    }
    const nextPage=()=>{
        if(timeSheetList && timeSheetList.length==10){
            let _pageNo = pageNo;
            setPageNo(++_pageNo);
        }
    }
    const prevPage =()=>{
        
        if(pageNo>1){
            let _pageNo = pageNo;
            setPageNo(--_pageNo);
        }
    }
    const loadStatusWise=(status)=>{
        setTimeSheetStatus(status);
    }
    const actionContent = (status, id) => {
        if ((status === "saved" || status === "rejected" || status === "submitted") && profile.role === "standard") {
            return (
                <>
                    <a data-toggle="modal" data-target="#modal-addTimeSheet" onClick={(e) => { setIsShow(true); setTimeSheetId(id); setIsView(false) }} className="dropdown-item" href="#"><i
                        className="fa fa-edit"></i> Edit</a>
                    <a style={{ cursor: 'pointer' }} onClick={(e) => { removeTimeSheet(id) }} className="dropdown-item" ><i
                        className="fa fa-trash"></i> Remove</a>
                </>);
        } else if (profile.role !== "standard") {
            return (<>
                <a data-toggle="modal" data-target="#modal-addTimeSheet" onClick={(e) => { setIsShow(true); setTimeSheetId(id); setIsView(true) }} className="dropdown-item" href="#"><i
                    className="fa fa-eye"></i> View</a>
                <a data-toggle="modal" data-target="#modal-addTimeSheet" onClick={(e) => { setIsShow(true); setTimeSheetId(id); setIsView(false) }} className="dropdown-item" href="#"><i
                    className="fa fa-edit"></i> Edit</a>
                <a style={{ cursor: 'pointer' }} onClick={(e) => { removeTimeSheet(id) }} className="dropdown-item" ><i
                    className="fa fa-trash"></i> Remove</a>
            </>)
        } else {
            return (<>
                <a data-toggle="modal" data-target="#modal-addTimeSheet" onClick={(e) => { setIsShow(true); setTimeSheetId(id); setIsView(true) }} className="dropdown-item" href="#"><i
                    className="fa fa-eye"></i> View</a>
            </>)
        }
    }
    return (
        <>
            <NevHeader routValues={{ pageRoute: '/timesheets', pageName: 'Manage Time Sheet' }} />
            <section className='content'>
                <div className='container-fluid'>
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Quick Search</h3>
                                    <div className="card-tools">
                                        {/* <button onClick={(e) => { showAddUsersPopup() }} type="button" data-toggle="modal" data-target="#modal-lg"
                                        className="btn btn-tool" title="Add new use">
                                        Add <i className="fas fa-plus-circle"></i>
                                    </button> */}
                                    </div>
                                </div>
                                {
                                    profile.role!="standard" ?
                                    <>
                                      <div>
                                    <Formik
                                        initialValues={initialValues}
                                        validationSchema={validationSchema}
                                        onSubmit={async (values, { resetForm }) => {
                                            await onSubmit(values);
                                            resetForm();
                                        }}
                                        enableReinitialize={true}
                                    >
                                        <Form>
                                            <div className="modal-body">
                                                <div className="row">
                                                <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>Select Users</label>
                                                            <select onChange={(e) => setUserId(e.target.value)} as="select" name="userId" className="form-control" style={{ width: '100%' }}>
                                                                <option value="">All</option>
                                                                {
                                                                    usersList && usersList.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <option value={item.id}>{item.name}</option>
                                                                            </>);
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-2">
                                                        <div className="form-group">
                                                            <label>Select Clients</label>
                                                            <select name="userId" className="form-control" onChange={(e) => { setClientId(e.target.value) }} style={{ width: '100%' }}>
                                                                <option value="All">All</option>
                                                                {
                                                                    clientsList && clientsList.map((item, index) => {
                                                                        return (
                                                                            <>
                                                                                <option value={item.id}>{item.name}</option>
                                                                            </>);
                                                                    })
                                                                }
                                                            </select>

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>Start Date</label>
                                                            <Field
                                                                className="form-control"
                                                                name="startdate"
                                                                component={DatePicker}
                                                                dateFormat="dd/MM/yyyy"
                                                                label="Start date"
                                                                selected={startDate}
                                                                onChange={(date) => setStartDate(date)}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-3">
                                                        <div className="form-group">
                                                            <label>End Date</label>
                                                            <Field
                                                                className="form-control"
                                                                name="startdate"
                                                                component={DatePicker}
                                                                minDate={startDate} 
                                                                dateFormat="dd/MM/yyyy"
                                                                label="Start date"
                                                                selected={endDate}
                                                                onChange={(date) => setEndDate(date)}
                                                            />

                                                        </div>
                                                    </div>
                                                    <div className="col-md-1">
                                                        <label>_</label>
                                                        <div className="form-group">
                                                            <button type="button" onClick={ (e) => { searchTimeSheet();loadTimeSheetCounts(); }} className="btn btn-primary"><i className='fa fa-search'></i></button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </Form>
                                    </Formik>
                                </div>
                                    </>:""
                                }
                              
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <WidgetTimeSheetCount data={timeSheetCounts}  loadStatusWise={loadStatusWise}></WidgetTimeSheetCount>
                </div>
            </section>
            <section className="content">
                <div className="container-fluid">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="card card-primary">
                                <div className="card-header">
                                    <h3 className="card-title">Request List</h3>
                                    <div className="card-tools">
                                        {/* <button type="button" data-toggle="modal" data-target="#modal-lg"
                                            className="btn btn-tool" title="Add new use">
                                            Add <i className="fas fa-plus-circle"></i>
                                        </button> */}
                                    </div>
                                </div>

                                <div>
                                    {/* <form>
                                        <div className="card-body">
                                            <div className="form-group">
                                                <div className="input-group input-group-sm">
                                                    <input placeholder="Search by name" type="text"
                                                        className="form-control" />
                                                    <span className="input-group-append">
                                                        <button type="button"
                                                            className="btn btn-info btn-flat">Search!</button>
                                                    </span>
                                                </div>
                                            </div>
                                        </div>
                                    </form> */}
                                </div>
                                <div className="card-body table-responsive p-0">
                                    <table className="table table-hover text-nowrap">
                                        <thead>
                                            <tr>
                                                <th>ID</th>
                                                <th>Requestor</th>
                                                <th>Client</th>
                                                <th>Location</th>
                                                <th>Date</th>
                                                <th>Time</th>
                                                <th>Duration</th>
                                                {/* <th>Created Date/By</th>
                                                <th>Modify Date/By</th> */}
                                                <th>Status</th>
                                                <th>Action</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            {
                                                timeSheetList && timeSheetList.map((item, index) => {
                                                    return (<>
                                                        <tr>
                                                            <td>{item.id}</td>
                                                            <td>{item.fullName}</td>
                                                            <td>{item.clientName}</td>
                                                            <td>{item.locationName}</td>
                                                            <td>{moment(item.start).format("DD/MM/YYYY")}</td>
                                                            <td>{item.startTime}</td>
                                                            <td>{item.hours}.{item.miniuts}</td>
                                                            <td><span className="tag tag-success">{item.status}</span></td>
                                                            <td>
                                                                <div className="margin">
                                                                    <div className="btn-group">
                                                                        <button type="button"
                                                                            className="btn btn-default">Action</button>
                                                                        <button type="button"
                                                                            className="btn btn-default dropdown-toggle dropdown-icon"
                                                                            data-toggle="dropdown">
                                                                        </button>
                                                                        <div className="dropdown-menu" role="menu">
                                                                           
                                                                            {actionContent(item.status,item.id)}
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    </>);
                                                })
                                            }



                                        </tbody>
                                    </table>
                                    <div class="card-footer clearfix">
                                    <ul class="pagination pagination-sm m-0 float-left">
                                    <li class="page-item">Total:({totalSize}) Page:{pageNo}</li>
                                    </ul>
                                        <ul class="pagination pagination-sm m-0 float-right">
                                            <li class="page-item"><a class="page-link" style={{cursor:'pointer'}} onClick={(e)=>{prevPage()}}>«</a></li>
                                            <li class="page-item"><a class="page-link"  style={{cursor:'pointer'}} onClick={(e)=>{nextPage()}}>»</a></li>
                                        </ul>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <>
                {
                    isShow ? <>
                        <AddTimeSheetPopup isView={isView} isShow={isShow} id={timeSheetId} modelTitle={"Edit Time Sheet"} onClose={(e) => { onClose() }} />
                    </> : ''
                }
            </>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </>);
}
export default ManageTimeSheet;