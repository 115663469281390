import { useEffect, useState } from 'react';
import ReactQuill from 'react-quill';
import * as yup from 'yup';
import 'react-quill/dist/quill.snow.css';
import DatePicker from "react-datepicker";
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import "react-datepicker/dist/react-datepicker.css";
import { getMultipleOf, getProfile } from './../../../utils/general';
import moment from 'moment';
import {

    getAllWorkTypeDD,
    getAllLocationDD,
    getAssignedClients,
    addTimeSheet,
    getTimeSheetById
} from '../../../httphandler/urlmapper';
const AddTimeSheetPopup = (props) => {
    const [workTypeDD, setWorkType] = useState([]);
    const [locationDD, setLocation] = useState([]);
    const [clintsList, setClientList] = useState([]);
    const [desc, setDesc] = useState();
    const [startDate, setStartDate] = useState();
    const [descCount, setdescCount] = useState(0);  
    const [status, setStatus] = useState('');
    const profile = getProfile();
    
    // Array to pass hours into dropdown selection
    const hours = Array.from(Array(24).keys());
    // Array to pass minutes into dropdown selection
    const minutes = getMultipleOf(60, 15);
    useEffect(() => {
        getDDWorkType();
        getAllClientsList();
        getLocationDD();
        if (props.id && props.id > 0) {
            loadTimeSheetById(props.id);    
        }
    }, [props])

    const [initialValues, setinitialValues] = useState({
        id: 0,
        workType: '',
        client: '',
        locations: '',
        startdate: '',
        startTime: '',
        hours: '',
        minits: '',
        status: 'saved',
        description: '',
        title: ''
    });
    const validationSchema = yup
        .object()
        .shape(
            {
                workType: yup.string().required('Please select worktype'),
              //  client: yup.string().required('Please select client'),
                locations: yup.string().required('Please select location'),
                startTime: yup.string().required('Please start time'),
                hours: yup.string().required('Please select hours'),
                minits: yup.string().required('Please select minutes'),
                title: yup.string().required('Please enter title')
            });
    const onSubmit = async (values) => {
        if (descCount === 0 && !desc) return toast.info('Please provide some short descriptions.');
        if (descCount > 250) return toast.info('Please provide short descriptions between 1 to 250.');
        if (!startDate) return toast.info('Please select start date.');
        if(profile.role!=='standard' && (!props.id || props.id===0)){
            values.status = "accepted";
        }
        let result = await addTimeSheet({ ...values, startdate: moment(startDate).format("YYYY-MM-DD"), description: desc });
        if (result.statusCode === 200) {

            toast.success("Your request has been " + values.status);
           // if (props.id && props.id > 0) {
                setTimeout(() => {
                    document.getElementById("closeFromid").click();

                }, 1000)
                //return;
           // }
            setStartDate('');
            setdescCount(0);
            setDesc('');
            return;
        }
    }
    const loadTimeSheetById = async (id) => {
        let result = await getTimeSheetById(id);
        if (result.statusCode === 200) {
            setinitialValues({
                id: result.data.id,
                workType: result.data.WorkTypeId,
                client: result.data.ClientId,
                locations: result.data.LocationId,
                startdate: result.data.startDate,
                startTime: result.data.startTime,
                hours: result.data.hours,
                minits: result.data.miniuts,
                status: result.data.status,
                title: result.data.title,
                description: result.data.description,
            });
            setDesc(result.data.description);
            setStartDate(new Date(result.data.startDate));
        }
        setStatus(result.data.status);

    }
    const getAllClientsList = async () => {
        const result = await getAssignedClients();
        if (result.data) {
            setClientList(result.data);
        }
    }
    const getDDWorkType = async () => {
        const result = await getAllWorkTypeDD();
        if (result.statusCode === 200) {
            setWorkType(result.data);
        }
    }
    const getLocationDD = async () => {
        const result = await getAllLocationDD();
        if (result.statusCode === 200) {
            setLocation(result.data);
        }
    }
   
    const renderError = (message) => <p className="help is-danger">{message}</p>
    const handleOnChange = (
        value,
        deltaOp,
        sources,
        editor
    ) => {
        setdescCount(editor.getLength() - 1)
        setDesc(value);
    }
    return (
        <>
            <div className="modal fade show" id="modal-addTimeSheet" aria-hidden="true" style={{ display: 'block' }}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">{props.modelTitle}</h4>
                            <button onClick={props.onClose} type="button" id="closeFromid" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <Formik
                            initialValues={initialValues}
                            validationSchema={validationSchema}
                            onSubmit={async (values, { resetForm }) => {
                                await onSubmit(values);
                                // resetForm();
                            }}
                            enableReinitialize={true}
                        >
                            <Form>
                                <div className="modal-body">
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="form-group">
                                                <label>Title</label>
                                                <Field type="text" name="title" className="form-control" style={{ width: '100%' }}></Field>
                                                <ErrorMessage name="title" render={renderError} />
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Work Type</label>
                                                <Field as="select" name="workType" className="form-control" style={{ width: '100%' }}
                                                    data-select2-id="1" tabindex="-1" aria-hidden="true">
                                                    <option value="">Select Work Type</option>
                                                    {
                                                        workTypeDD && workTypeDD.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <option value={item.id}>{item.workTypeName}</option>
                                                                </>);
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="workType" render={renderError} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Client</label>
                                                <Field as="select" name="client" className="form-control" style={{ width: '100%' }}
                                                    data-select2-id="1" tabindex="-1" aria-hidden="true">
                                                    <option value="">Select Client</option>
                                                    {
                                                        clintsList && clintsList.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <option value={item.id}>{item.name}</option>
                                                                </>);
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="client" render={renderError} />
                                            </div>
                                        </div>
                                        <div className="col-md-4">
                                            <div className="form-group">
                                                <label>Locations</label>
                                                <Field as="select" name="locations" className="form-control" style={{ width: '100%' }}
                                                    data-select2-id="1" tabindex="-1" aria-hidden="true">
                                                    <option value="">Select Location</option>
                                                    {
                                                        locationDD && locationDD.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <option value={item.id}>{item.locationName}</option>
                                                                </>);
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="locations" render={renderError} />
                                            </div>
                                        </div>

                                    </div>
                                    <div className='row'>
                                        <div className="col-md-3">
                                            <div className="form-group">
                                                <label>Start Date</label>
                                                <Field
                                                    className="form-control"
                                                    name="startdate"
                                                    component={DatePicker}
                                                    label="Start date"
                                                    dateFormat="dd/MM/yyyy"
                                                    selected={startDate}
                                                    onChange={(date) => setStartDate(date)}
                                                />
                                                <ErrorMessage name="startdate" render={renderError} />
                                                {/* <DatePicker placeholderText='Select Date' className='form-control' selected={startDate} onChange={(date: Date) => setStartDate(date)} /> */}
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <div className="form-group">
                                                <label>Start Time</label>
                                                <Field
                                                    className="form-control"
                                                    name="startTime"
                                                    type="time"
                                                />
                                                <ErrorMessage name="startTime" render={renderError} />
                                                {/* <DatePicker placeholderText='Select Date' className='form-control' selected={startDate} onChange={(date: Date) => setStartDate(date)} /> */}
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label>Hours</label>
                                            <div className="form-group">
                                                <Field
                                                    as="select"
                                                    name="hours"
                                                    className="form-control"
                                                    style={{ width: '100%' }}
                                                >
                                                    <option value=''>Select hours</option>
                                                    {
                                                        hours && hours.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <option value={item}>{item}</option>
                                                                </>);
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="hours" render={renderError} />
                                            </div>
                                        </div>
                                        <div className="col-md-2">
                                            <label>Minutes</label>
                                            <div className="form-group">
                                                <Field
                                                    as="select"
                                                    name="minits"
                                                    className="form-control"
                                                    style={{ width: '100%' }}>
                                                    <option value=''>Select Minutes</option>
                                                    {
                                                        minutes && minutes.map((item, index) => {
                                                            return (
                                                                <>
                                                                    <option value={item}>{item}</option>
                                                                </>);
                                                        })
                                                    }
                                                </Field>
                                                <ErrorMessage name="minits" render={renderError} />

                                            </div>
                                        </div>
                                        {
                                            // (profile.role === 'standard') ||  (props.id && props.id>0)?<>
                                                
                                            // </>:''
                                            <div className="col-md-2">
                                                    <label>Status</label>
                                                    <div className="form-group">
                                                        <Field
                                                            as="select"
                                                            name="status"
                                                            className="form-control"
                                                            style={{ width: '100%' }}>
                                                            {/* {
                                                        profile.role === 'standard' && status!=='accepted'? <>
                                                            <option value='saved'>Saved</option>
                                                            <option value='submitted'>Submitted</option>
                                                        </> : <option value='accepted'>Accepted</option>
                                                    } */}

                                                        {
                                                            profile.role === "standard" ?
                                                                <>
                                                                    <option value='saved'>Saved</option>
                                                                    <option value='submitted'>Submitted</option>
                                                                </> : <>
                                                                    <option value='saved'>Saved</option>
                                                                    <option value='submitted'>Submitted</option>
                                                                    <option value='accepted'>Accepted</option>
                                                                    <option value='rejected'>Rejected</option>
                                                                </>
                                                        }

                                                           
                                                           


                                                        </Field>

                                                    </div>
                                                </div>
                                        }
                                       
                                    </div>
                                    <div className='row'>
                                        <label>Description</label>
                                        <div className='col-md-12'>
                                            <ReactQuill
                                                id="quillEditor"
                                                theme="snow"
                                                value={desc}
                                                onChange={handleOnChange}
                                            />
                                            <div class="d-flex justify-content-between">
                                                <div>
                                                Characters: {descCount} / 250
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                                <div className="modal-footer justify-content-between">
                                    <button type="button" className="btn btn-default" onClick={props.onClose} data-dismiss="modal">Close</button>
                                    {
                                        !props.isView || profile.role !== "standard" ?
                                            <>
                                                <button type="submit" className="btn btn-primary">Update changes</button>
                                            </> : ""
                                    }
                                </div>
                            </Form>
                        </Formik>

                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </>);


}
export default AddTimeSheetPopup;