import { createContext, useState } from "react";

const ProfileProviderContext = createContext({});

export const PrfileProvider = ({ children }) => {
  const [profile, setProfile] = useState();

  return (
    <ProfileProviderContext.Provider value={{ profile, setProfile }}>
      {children}
    </ProfileProviderContext.Provider>
  );
};

export default ProfileProviderContext;
