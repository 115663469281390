import { Button, Modal } from 'react-bootstrap';
import { useState, useEffect } from 'react';
import * as yup from 'yup';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import { changePassword } from './../../httphandler/urlmapper';
import { ToastContainer, toast } from 'react-toastify';
const ResetPasswordDialog = (props) => {
    const [initialValues, setinitialValues] = useState({
        password: '',
        newPassword: '',
        confirmPassword: ''
    });
    const validationSchema = yup
        .object()
        .shape(
            {
                password: yup.string().required('Please add current password.')
                    .min(5, 'Password must be at least 5 characters long.')
                    .max(50, 'Password must not exceed 50 characters.'),
                newPassword: yup.string().required('Please add new password.')
                    .min(5, 'Password must be at least 5 characters long.')
                    .max(50, 'Password must not exceed 50 characters.'),
                confirmPassword: yup.string().required('Please add new password.')
                    .min(5, 'Password must be at least 5 characters long.')
                    .max(50, 'Password must not exceed 50 characters.')
                    .oneOf([yup.ref('newPassword'), null], 'Passwords must match'),
            });
    const onSubmit = async (values) => {
        debugger;
        const result = await changePassword({ password: values.password,newPassword: values.confirmPassword });
        if (result && result.statusCode === 200) {
            toast.success("Your password has been updated successfully.");
        } else {
            toast.error("Your current password not matched.");
        }
    }

    const onClose = () => {
        props.onHide();
    }
    const renderError = (message) => <p className="help is-danger">{message}</p>
    return (
        <>
            <Modal show={props.show} onHide={() => onClose()}>
                <Modal.Header>Change Password
                    <button onClick={props.onHide} type="button" id="closeFromid" className="close" data-dismiss="modal" aria-label="Close">
                        <span aria-hidden="true">×</span>
                    </button>
                </Modal.Header>
                <Modal.Body>
                    <Formik
                        initialValues={initialValues}
                        validationSchema={validationSchema}
                        onSubmit={async (values, { resetForm }) => {
                            await onSubmit(values);
                            resetForm();
                        }}
                        enableReinitialize={true}
                    >
                        <Form>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="form-group">


                                        <div className="col-md-12" data-select2-id="30">
                                            <div className="form-group" data-select2-id="29">
                                                <label>Current Password</label>
                                                <Field type="password" name="password" className="form-control" />
                                                <ErrorMessage name="password" render={renderError} />
                                            </div>
                                        </div>
                                        <div className="col-md-12" data-select2-id="30">
                                            <div className="form-group" data-select2-id="29">
                                                <label>New Password</label>
                                                <Field type="password" name="newPassword" className="form-control" />
                                                <ErrorMessage name="newPassword" render={renderError} />
                                            </div>
                                        </div>
                                        <div className="col-md-12" data-select2-id="30">
                                            <div className="form-group" data-select2-id="29">
                                                <label>Confirm Password</label>
                                                <Field type="password" name="confirmPassword" className="form-control" />
                                                <ErrorMessage name="confirmPassword" render={renderError} />
                                            </div>
                                        </div>


                                    </div>
                                </div>
                            </div>
                            <div className="modal-footer">
                                <button type="submit" className="btn btn-primary">Change Password</button>
                            </div>
                        </Form>
                    </Formik>

                </Modal.Body>

            </Modal>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </>
    )

}

export default ResetPasswordDialog;