import { doGet, doPost } from "../utils/http";

export const loginRequest = async (data) => {
    return doPost('/auth/login', data)
}
export const addUsersRequest = async (data) => {
    return doPost('/auth/register', data)
}
export const updateUsersRequest = async (data) => {
    return doPost('/auth/update', data)
}
export const sendResetPasswordEmail = async (id) => {
    return doPost('/auth/resetpasswordemail', {id:id})
}
export const sendForgotPasswordEmail = async (email) => {
    return doPost('/auth/forgotpasswordRequest', {email})
}
export const changePassword = async (data) => {
    return doPost('/auth/changePassword',data);
}
export const getAllUsers = async () =>{
    return doGet('/auth');
}
export const getUsersListDD = async () =>{
    return doGet('/auth/getUsersList');
}
export const getAllClients = async () =>{
    return doGet('/clients');
}
export const addClientRequest = async (data) =>{
    return doPost('/clients/add',data);
}
export const updateClientRequest = async (data) =>{
    return doPost('/clients/update',data);
}
export const getClientsListDropDown = async () =>{
    return doGet('/clients/clientsDropDown');
}
export const assignClientToUser = async (data) =>{
    return doPost('/clientassignation/add',data);
}
export const getClientsByUserId = async (id) =>{
    return doGet('/clientassignation/getClientsByUserId?id='+id);
}
export const getAssignedClients = async () =>{
    return doGet('/clientassignation/getLogedinClientsByUserId');
}
export const getAllWorkTypeDD = async () =>{
    return doGet('/workTypes');
}
export const getAllLocationDD = async () =>{
    return doGet('/locations');
}
export const addUpdateLocations = async (data) =>{
    return doPost('/locations',data);
}
export const addUpdateWorkType = async (data) =>{
    return doPost('/workTypes',data);
}
export const addTimeSheet = async (data) =>{
    return doPost('/timesheets',data);
}
export const getTimeSheetCount = async (data) =>{
    return doGet('/timesheets/timeSheetCounts',data);
}
export const getAllTimeSheetsByStatus = async (status,userId,clientId,start,end,pageNo,pageSize) =>{
    return doGet('/timesheets/allRequestByStatus?status='+status+"&userId="+userId+"&clientId="+clientId+"&start="+start+"&end="+end+"&pageNo="+pageNo+"&pageSize="+pageSize);
}
export const deleteTimeSheet = async (id) =>{
    return doPost('/timesheets/remove/'+id);
}
export const getTimeSheetById = async (id) =>{
    return doGet('/timesheets/getTimeSheetById?id='+id);
}
export const getApprovedTimeSheet = async (id,startDate,endDate) =>{
    return doGet('/timesheets/approvedTimeSheets?id='+id+'&startDate='+startDate+'&endDate='+endDate);
}
export const getReports = async (data) =>{
    return doPost('/reports/generateReports',data);
}
export const getReportsSummery = async (data) =>{
    return doPost('/reports/generateSumeryReports',data);
}
export const getAllSummaryRecords = async (data) =>{
    return doPost('/reports/generateAllReportsSummary',data);
}
export const generateAllReports = async (data) =>{
    return doPost('/reports/generateAllReports',data);
}

// Organization
export const addUpdateOrganization = async (data) =>{
    return doPost('/organisations/addUpdate',data);
}
export const getAllOrganizations = async () =>{
    return doGet('/organisations');
}
export const getUsersByOrgId = async (id)=>{
    return doGet('organisations/getUsersByOrgId?id='+id);
}
export const removeUserById =async (id)=>{
    return doGet('auth/removeusers?id='+id);
}