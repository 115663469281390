import { useState, useEffect } from 'react';
import { addUpdateOrganization, getUsersByOrgId, removeUserById,sendResetPasswordEmail } from '../../../httphandler/urlmapper';
import { ToastContainer, toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import 'react-toastify/dist/ReactToastify.css';
import * as yup from 'yup';
import "react-datepicker/dist/react-datepicker.css";
import DatePicker from "react-datepicker";
const OrganizationListPups = (props) => {

    useEffect(() => {
        props.id > 0 &&
            getUsers(props.id);
    }, [props]);
    const [orgState, setOrgState] = useState(props?.data?.is_Active);
    const [initialValues, setinitialValues] = useState({
        id: props?.data?.id,
        orgName: props?.data?.orgName,
        usersAllowed: props?.data?.usersAllowed,
        is_Active: props?.data?.is_Active,
    });
    const [usersList, setUsersList] = useState([]);
    const getUsers = async (id) => {
        const result = await getUsersByOrgId(id);
        if (result.statusCode === 200) {
            setUsersList(result.data);
        }
    }
    const validationSchema = yup
    .object()
    .shape({});
    const onSubmit = async (values)=>{
        const result = await addUpdateOrganization(values);
        if(result.statusCode===200){
            toast.success("Organization updated successfully.");
        }
    }
    const onActiveInActiveOrg = () => {
        setOrgState(!orgState);
    }
    const removeUser =async (id)=>{
        const result = await removeUserById(id);
        if(result.statusCode===200){
            await getUsers(props.id);
            toast.success("User removed successfully.");
        }
    }
    const resetPassword = async (id)=>{
        let result = await sendResetPasswordEmail(id);
        if(result && result.statusCode === 200){
            toast.success('Reset password email has been sent.');
        }
    }
    const renderError = (message) => <p className="help is-danger">{message}</p>
    return (
        <>
            <div className="modal fade show" id="modal-addTimeSheet" aria-hidden="true" style={{ display: 'block' }}>
                <div className="modal-dialog modal-xl">
                    <div className="modal-content">
                        <div className="modal-header">
                            <h4 className="modal-title">Add Update Users</h4>
                            <button onClick={async (e)=>{await props.onClose()}} type="button" id="closeFromid" className="close" data-dismiss="modal" aria-label="Close">
                                <span aria-hidden="true">×</span>
                            </button>
                        </div>
                        <div className="modal-body">
                            <div className="card-body table-responsive p-0">
                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={async (values, { resetForm }) => {
                                        await onSubmit(values);
                                    }}
                                    enableReinitialize={true}
                                >
                                     <Form>
                                        <div className="modal-body">
                                            <div className="row">
                                                <div className="col-md-3">
                                                    <div className="form-group">
                                                        <label>Organization Name</label>
                                                        <Field
                                                            type="text"
                                                            className="form-control"
                                                            name="orgName"
                                                        />
                                                    </div>
                                                    <ErrorMessage name="orgName" render={renderError} />
                                                </div>

                                                <div className="col-md-2">
                                                    <div className="form-group">
                                                        <label>No of Users Allowed</label>
                                                        <Field
                                                            type="number"
                                                            className="form-control"
                                                            name="usersAllowed"
                                                        />
                                                    </div>
                                                    <ErrorMessage name="usersAllowed" render={renderError} />
                                                </div>
                                                <div className="col-md-1">
                                                    <label>Status</label>
                                                    <div>
                                                        <Field onClick={onActiveInActiveOrg} type="checkbox" name="is_Active" id="customSwitch3" />
                                                        &nbsp;
                                                        <label className="" htmlFor="customSwitch3">
                                                            {
                                                                orgState ?
                                                                    <span style={{ color: 'green' }}>Active</span> :
                                                                    <span style={{ color: 'red' }}>Inactive</span>
                                                            } </label>
                                                    </div>

                                                </div>
                                                <div className="col-md-1">
                                                    <label>_</label>
                                                    <div className="form-group">
                                                        <button type="submit" className="btn btn-primary">
                                                        {
                                                            initialValues.id && initialValues.id>0 ? "Update":"Add"
                                                        }
                                                        </button>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Form>
                                </Formik>
                            </div>
                            <div className="card-body table-responsive p-0">
                                <table className="table table-hover text-nowrap">
                                    <thead>
                                        <tr>
                                            <th>Sr#</th>
                                            <th>ID</th>
                                            <th>User</th>
                                            <th>Email</th>
                                            <th>Role</th>
                                            <th>Status</th>
                                            <th>Password Rest</th>
                                            <th>Action</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        {
                                            usersList && usersList.length > 0 ? usersList.map((item, index) => {
                                                return (<>
                                                    <tr key={index}>
                                                        <td>{index + 1}</td>
                                                        <td>{item.id}</td>
                                                        <td>{item.fullName}</td>
                                                        <td>{item.username}</td>
                                                        <td>{item.role}</td>
                                                        <td>  {
                                                            item.status ? <><span style={{ color: 'green' }}>Active</span></> : <><span style={{ color: 'red' }} className="text-danger"> Inactive

                                                            </span></>
                                                        }</td>
                                                        <td><span onClick={async (e) => { await resetPassword(item.id) }} style={{ cursor: 'pointer', color: 'green', textDecoration: 'underline' }}>Reset Password</span> </td>
                                                        <td><span onClick={async (e) => { await removeUser(item.id) }} style={{ cursor: 'pointer', color: 'red', textDecoration: 'underline' }}>Remove</span> </td>
                                                    </tr>
                                                </>);
                                            }) : <>  <tr className="no-data">
                                                <td colspan="8" style={{textAlign:'center',color:'red'}}>No data found !</td>
                                            </tr></>
                                        }



                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
            />
            {/* Same as */}
            <ToastContainer />
        </>);
}
export default OrganizationListPups